import React from 'react'
import { useLanguagesContext } from '@/context/languages'
import Icon from '@/components/Icon'

type Props = {
  // Not needed for this component, but here for reference
  // blok: SbBlokData & ISbUtilLanguageSwitchV2
  size?: 'sm' | 'md'
}

const UtilLanguageSwitchV2 = ({ size = 'md' }: Props) => {
  const { languages } = useLanguagesContext()

  const changeLanguage = (url: string) => {
    window.location.href = url
  }

  return (
    <div className="group relative flex w-fit items-center gap-1.5">
      <div className={`${size === 'sm' ? 'h-4 w-4' : 'h-6 w-6'} absolute`}>
        <Icon name={'world'} className="h-full w-full [&_path]:!fill-v2-text-primary" />
      </div>
      <select
        aria-label="Language switch"
        className={`cursor-pointer appearance-none border-none !bg-transparent !py-0 font-bold text-v2-text-primary group-hover:text-v2-primary-static-1 group-hover:underline ${size === 'sm' ? 'caption-bold' : ''} ${size === 'sm' ? '!px-5' : '!px-8'} relative z-10`}
        style={{ backgroundImage: 'none' }}
        onChange={(e) => changeLanguage(e.target.value)}
      >
        {languages.map((language, index) => (
          // First language is selected by default
          <option key={language.name} value={language.url} defaultChecked={index === 0}>
            {language.name}
          </option>
        ))}
      </select>
      <div className={`${size === 'sm' ? 'h-4 w-4' : 'h-6 w-6'} absolute right-0`}>
        <Icon
          name={'chevron-down-v2'}
          className="h-full w-full [&_path]:!fill-v2-text-primary group-hover:[&_path]:!fill-v2-primary-static-1"
        />
      </div>
    </div>
  )
}

export default UtilLanguageSwitchV2
