import type { AppProps } from 'next/app'
import Head from 'next/head'
import { storyblokInit, apiPlugin } from '@storyblok/react'
import Page from './[[...slug]]'
import Blocks from '@/blocks'
import '@/styles/globals.scss'
// Font
import { Lato, Bitter } from 'next/font/google'

const latoFont = Lato({
  weight: ['400', '700'],
  subsets: ['latin'],
  variable: '--font-lato',
})
const bitterFont = Bitter({
  weight: ['400'],
  subsets: ['latin'],
  variable: '--font-bitter',
})
const components = {
  page: Page,
  ...Blocks,
}

storyblokInit({
  accessToken: process.env.storyblokApiToken,
  use: [apiPlugin],
  components,
  apiOptions: {
    cache: {
      clear: 'auto',
      type: 'memory',
    },
  },
})

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <main className={`${latoFont.variable} ${bitterFont.variable} font-lato`}>
        <Component {...pageProps} />
      </main>
    </>
  )
}
