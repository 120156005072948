import React, { FC } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import Icon from '@/components/Icon'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

type Props = {
  blok: SbBlokData & ISbChildrenExpertsItemV2
}

const ItemExpertV2: FC<Props> = ({ blok }: Props) => {
  const isClickable = !!blok.link?.url
  const router = useRouter()

  const content = (
    <div
      {...storyblokEditable(blok)}
      className={classNames(
        'rounded-5xl border border-v2-foreground-static-tertiary bg-v2-secondary-static-1 p-8 transition-colors md:p-10',
        {
          'cursor-pointer hover:bg-v2-secondary-static-2': isClickable,
        },
      )}
    >
      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className={classNames('relative shrink-0', isClickable ? 'h-18 w-18 md:h-32 md:w-32' : 'h-40 w-40')}>
          <div
            className={classNames('absolute inset-0 z-10 rounded-full border border-v2-foreground-static-tertiary', {
              'bg-v2-foreground-static-tertiary': !blok.image,
            })}
          ></div>
          {!!blok.image && (
            <BaseImageV2
              src={blok.image.filename}
              alt={blok.image.alt}
              className="box-border block h-full w-full overflow-hidden rounded-full [&_img]:h-full [&_img]:w-full"
              breakPointsWidthMap={isClickable ? { 0: 72, 768: 128 } : { 0: 160 }}
              objectCover
            />
          )}
        </div>
        <div className="flex w-full flex-col justify-center gap-2">
          {blok.kicker && <span className="heading-6 text-v2-text-primary">{blok.kicker}</span>}
          <div className="flex items-center gap-2">
            <h2 className={classNames('heading-4 text-v2-text-primary', { 'md:heading-3': !isClickable })}>{blok.title}</h2>
            <Icon name="badge-check" className="h-8 w-8 shrink-0 text-v2-primary-static-1" />
          </div>
          {blok.subtitle && <p className="body-2 md:body-1 !font-bold text-v2-text-secondary">{blok.subtitle}</p>}
          <p className="body-2 md:body-1 text-v2-text-secondary">{blok.text}</p>
        </div>
      </div>
    </div>
  )

  return isClickable ? (
    <Link href={{ pathname: blok.link?.url || '', query: router.query }}>{content}</Link>
  ) : (
    <div>{content}</div>
  )
}

export default ItemExpertV2
