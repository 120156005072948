import React from 'react'
import { SbBlokData } from '@storyblok/react'
import BaseRichText from '@/v2/base/BaseRichText'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import classNames from 'classnames'

type Props = {
  blok: SbBlokData & IBlogContentCalloutV2
}

const BlogContentCalloutV2 = ({ blok }: Props) => {
  return (
    <div
      className={classNames(
        'flex w-full rounded-xl bg-v2-secondary-static-1 p-8 text-v2-text-primary lg:p-10',
        blok.is_avatar ? 'flex-col gap-x-8 gap-y-7 md:flex-row' : 'gap-4',
      )}
    >
      {blok.icon?.filename && (
        <div
          className={classNames(
            blok.is_avatar ? 'relative size-18 shrink-0 overflow-hidden rounded-full md:size-32' : 'size-6 w-fit shrink-0',
          )}
        >
          <BaseImageV2
            src={blok.icon.filename}
            alt={blok.icon.alt}
            breakPointsWidthMap={blok.is_avatar ? { 0: 72, 768: 128 } : { 0: 24 }}
          />

          {blok.is_avatar && <div className="absolute inset-0 rounded-full border border-v2-foreground-static-tertiary"></div>}
        </div>
      )}
      <div className="break-word prose-styling w-full prose-p:my-0 prose-hr:my-4">
        <BaseRichText value={blok.richtext} />
      </div>
    </div>
  )
}

export default BlogContentCalloutV2
