import React, { LinkHTMLAttributes } from 'react'
import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import {
  ISbStoryData,
  getStoryblokApi,
  useStoryblokState,
  StoryblokComponent,
  SbBlokData,
  ISbStoriesParams,
  ISbResult,
} from '@storyblok/react'
import map from 'lodash/map'
import first from 'lodash/first'
import get from 'lodash/get'
// Interface
import ScriptProps from '@/interfaces/components/script.interface'
import BaseLayoutV2 from '@/v2/base/BaseLayoutV2'
import type ISEO from '@/interfaces/components/seo.interface'
import { LANGUAGE_HTML_LANG_MAP } from '@/context/languages'

// Lazy loaded Components
const Layout = dynamic(() => import('@/components/Layout'))
const SEO = dynamic(() => import('@/components/SEO'))
const Script = dynamic(() => import('@/components/Script'))

type Props = {
  defaultLocale: string
  locale: string
  locales: string[]
  story: ISbStoryData
  settings: ISbStoryData<ISbCoreSettingsV2> | false
  config: ISbStoryData | false
  childData: Record<string, ISbStoryData[]>
}

const V2_CONTENT_TYPES = [
  'corePageV2',
  'coreBlogPostV2',
  'coreComponentV2',
  'corePageTextV2',
  'coreExpertsOverviewV2',
  'coreExpertProfileV2',
  'coreBlogOverviewV2',
]
const RESOLVE_RELATIONS = [
  'page.configReference',
  'page.headerReference',
  'page.footerReference',

  // Needed for V2
  'corePageV2.header',
  'corePageV2.footer',
  'corePageTextV2.header',
  'corePageTextV2.footer',
  'coreBlogPostV2.header',
  'coreBlogPostV2.footer',
  'coreBlogPostV2.category',
  'coreBlogPostV2.related_posts',
  'corePageTextV2.header',
  'corePageTextV2.footer',
  'coreExpertProfileV2.header',
  'coreExpertProfileV2.footer',
  'coreExpertsOverviewV2.header',
  'coreExpertsOverviewV2.footer',
  'coreBlogOverviewV2.related_posts',
  'childrenBlogAuthorV2.author',
  'sectionReferenceV2.body',
  'coreBlogOverviewV2.featured_post',
]

export default function Page(props: Props) {
  const story = useStoryblokState(props.story, {
    resolveRelations: RESOLVE_RELATIONS,
  })
  const contentConfig = useStoryblokState(get(props.story, 'content.configReference'))
  const config = props.config || contentConfig
  const header = useStoryblokState(get(props.story, 'content.headerReference'))
  const footer = useStoryblokState(get(props.story, 'content.footerReference'))

  const pageMetatags = get(story, 'content.metatags', {})
  const links: Array<SbBlokData & LinkHTMLAttributes<HTMLLinkElement>> = get(config, 'content.links', [])
  const scripts: Array<SbBlokData & ScriptProps> = get(config, 'content.scripts', [])
  const pageLinks: Array<SbBlokData & LinkHTMLAttributes<HTMLLinkElement>> = get(story, 'content.links', [])
  const pageScripts: Array<SbBlokData & ScriptProps> = get(story, 'content.scripts', [])
  const pageHeader = first(get(story, 'content.header', []))
  const pageBody: Array<SbBlokData> = get(story, 'content.body', [])
  const pageFooter = first(get(story, 'content.footer', []))

  const headerContent = pageHeader ? pageHeader : get(header, 'content', [])
  const footerContent = pageFooter ? pageFooter : first(get(footer, 'content.content', []))

  let content: JSX.Element | null = null

  // if page is a V2 page and settings are available, render the V2 layout
  if (V2_CONTENT_TYPES.includes(story?.content.component as string) && props.settings) {
    content = (
      <BaseLayoutV2 settings={props.settings} story={story as ISbStoryData<ISbCoreContentType>} childData={props.childData} />
    )
  } else {
    content = (
      <>
        <StoryblokComponent blok={headerContent} locales={props.locales} locale={props.locale} />
        <Layout>
          {map(pageBody, (body) => (
            <StoryblokComponent key={body._uid} blok={body} locales={props.locales} locale={props.locale} />
          ))}
        </Layout>
        <StoryblokComponent blok={footerContent} locales={props.locales} locale={props.locale} />
      </>
    )
  }

  return (
    <>
      <Head>
        <title>{story?.content.title || (pageMetatags as ISEO['metatags'])?.title || 'BetterDoc.org'}</title>
        {story?.content.canonical && <link rel="canonical" href={story.content.canonical} />}
        {story?.content.robots && <meta name="robots" content={story.content.robots} />}
        <SEO metatags={pageMetatags} />
        {React.Children.toArray(links.map((l) => <link {...l} />))}
        {story?.alternates &&
          story.alternates.map((alternate) => {
            const locale = alternate.full_slug.split('/')[0]
            const hrefLang = LANGUAGE_HTML_LANG_MAP[locale]

            return (
              <link
                key={alternate.id}
                href={`https://betterdoc.org/${alternate.full_slug}`}
                hrefLang={hrefLang}
                rel="alternate"
              />
            )
          })}
        {map([...links, ...pageLinks], (link) => (
          <link key={link._uid} {...link} />
        ))}
      </Head>
      {content}
      {/*  */}
      {map([...scripts, ...pageScripts], (script) => (
        <Script key={script._uid} {...script} />
      ))}
    </>
  )
}

export const getServerSideProps = async (props: GetServerSidePropsContext<{ slug: string[] }>) => {
  const storyblokApi = getStoryblokApi()
  const slug = props.params?.slug ? props.params.slug.join('/') : ''

  const queryParam = props.query

  const sbParams: ISbStoriesParams = {
    version: 'published',
    // Commented for testing as per the documention (https://www.storyblok.com/docs/guide/in-depth/internationalization#dimensions-app)
    // No need if we are using folder level translations
    // language: props.locale,
  }

  /**
   * Changing version if the content is loaded inside storyblok.
   * Initializing additional `sbParams` for enabling draft preview mode for multiple releases
   */
  const isStoryblokDraftMode = queryParam._storyblok ? true : false
  if (isStoryblokDraftMode) {
    sbParams.version = 'draft'
    sbParams.from_release = queryParam[`_storyblok_release`] as string
    sbParams.cv = Number(queryParam[`_storyblok_tk[timestamp]`])
  }

  try {
    const page = await storyblokApi.get(`cdn/stories/${props.locale}/${slug}`, {
      ...sbParams,
      resolve_relations: RESOLVE_RELATIONS,
    })

    if (!page) {
      return { notFound: true }
    }

    // V2 fetches settings
    let settings: ISbResult | undefined
    let config: ISbResult | undefined

    if (V2_CONTENT_TYPES.includes(page.data?.story.content.component as string)) {
      settings = await storyblokApi.get(`cdn/stories/${props.locale}/global/v2`, {
        ...sbParams,
        resolve_relations: ['coreSettingsV2.header', 'coreSettingsV2.footer'],
      })
      config = await storyblokApi.get(`cdn/stories/${props.locale}/global/config`, {
        ...sbParams,
      })
    }

    const childData: Record<string, ISbStoryData[]> = {}

    // V2 fetch additional data

    // Blog overview
    if (page.data?.story?.content?.component === 'coreBlogOverviewV2') {
      // Categories will never be over 20, so we can fetch all at once
      const resultCategories = await storyblokApi.get('cdn/stories', {
        ...sbParams,
        per_page: 20,
        starts_with: props.locale,
        'filter_query[component][in]': 'coreBlogCategoryV2',
      } as ISbStoriesParams)

      childData['categories'] = resultCategories.data.stories

      // Blog posts
      const posts: ISbStoryData[] = []

      const sbBaseParams = {
        ...sbParams,
        per_page: 100,
        starts_with: props.locale,
        'filter_query[component][in]': 'coreBlogPostV2',
        resolve_relations: ['coreBlogPostV2.category'],
        sort_by: 'first_published_at:desc',
      }

      const perPage = 100
      const firstResult = await storyblokApi.get('cdn/stories', sbBaseParams as ISbStoriesParams)

      posts.push(...firstResult.data.stories)

      const lastPage = firstResult.total ? Math.ceil(firstResult.total / perPage) : 1

      // Loop through all pages
      for (let currentPage = 2; currentPage <= lastPage; currentPage++) {
        const result = await storyblokApi.get('cdn/stories', {
          ...sbBaseParams,
          page: currentPage,
        } as ISbStoriesParams)

        posts.push(...result.data.stories)
      }

      childData['posts'] = posts
    }

    // Experts overview
    if (page.data?.story?.content?.component === 'coreExpertsOverviewV2') {
      const experts: ISbStoryData[] = []

      const perPage = 100
      const firstResult = await storyblokApi.get('cdn/stories', {
        ...sbParams,
        per_page: perPage,
        starts_with: props.locale,
        'filter_query[component][in]': 'coreExpertProfileV2',
      } as ISbStoriesParams)

      experts.push(...firstResult.data.stories)

      const lastPage = firstResult.total ? Math.ceil(firstResult.total / perPage) : 1

      // Loop through all pages
      for (let currentPage = 2; currentPage <= lastPage; currentPage++) {
        const result = await storyblokApi.get('cdn/stories', {
          ...sbParams,
          per_page: 100,
          starts_with: props.locale,
          'filter_query[component][in]': 'coreExpertProfileV2',
          page: currentPage,
        } as ISbStoriesParams)

        experts.push(...result.data.stories)
      }

      childData['experts'] = experts
    }

    return {
      props: {
        defaultLocale: props.defaultLocale,
        locale: props.locale,
        locales: props.locales,
        story: page.data?.story || false,
        settings: settings?.data.story || false,
        config: config?.data.story || false,
        childData,
      },
    }
  } catch (err) {
    return { notFound: true }
  }
}

// **** SSG SETUP ****
// type StaticPaths = {
//   locales: string[]
//   defaultLocale: string
// }

// export async function getStaticPaths(props: StaticPaths) {
//   const storyblokApi = getStoryblokApi()
//   const { data } = await storyblokApi.get('cdn/links', {
//     version: isProd ? 'published' : 'draft',
//   })
//   const paths: { params: { slug: string[] } | { slug: string }; locale: string }[] = []
//   Object.keys(data.links).forEach((linkKey) => {
//     if (data.links[linkKey].is_folder || includes(data.links[linkKey].slug, 'global')) {
//       return
//     }
//     if (data.links[linkKey].slug === 'home') {
//       for (const locale of props.locales) {
//         paths.push({ params: { slug: [''] }, locale })
//       }

//       return
//     }
//     const slug = data.links[linkKey].slug
//     const [locale, ...restPath] = slug.split('/')
//     if (includes(props.locales, locale)) paths.push({ params: { slug: restPath }, locale })
//   })

//   return {
//     paths: paths,
//     fallback: false,
//   }
// }
