import React from 'react'
import { SbBlokData } from '@storyblok/react'

type Props = {
  blok: SbBlokData & IBlogContentVideoV2
}

const BlogContentVideoV2 = ({ blok }: Props) => {
  const isVideoEmbed = blok.video_embed?.length > 0

  return (
    <div>
      <div className="overflow mb-1.5">
        {isVideoEmbed && (
          <iframe
            className="aspect-video h-full w-full rounded-xl border border-v2-foreground-static-tertiary "
            src={blok.video_embed}
            title={blok.caption}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        {!isVideoEmbed && blok.video?.filename && (
          <video controls className="w-full rounded-xl border border-v2-foreground-static-tertiary">
            <source src={blok.video.filename} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      {blok.caption && <div className="body-3 text-v2-foreground-static-secondary">{blok.caption}</div>}
    </div>
  )
}

export default BlogContentVideoV2
