import dynamic from 'next/dynamic'

/**
 * Storyblok blocks
 */
// CTA Blocks
const HeadingWithCTAButton = dynamic(() => import('./CTASection/HeadingWithCTAButton'))
// Feature blocks
const AlternateImageWithFeatureList = dynamic(() => import('./FeatureSection/AlternateImageWithFeatureList'))
const FeatureListWithCTAs = dynamic(() => import('./FeatureSection/FeatureListWithCTAs'))
const FAQAsAccordion = dynamic(() => import('./FeatureSection/FAQAsAccordion'))
const ImageWithFeatureListAndCTAs = dynamic(() => import('./FeatureSection/ImageWithFeatureListAndCTAs'))
// Hero blocks
const VisualImageWithHeading = dynamic(() => import('./HeroSection/VisualImageWithHeading'))
const VideoEmbeddedWithCTA = dynamic(() => import('./HeroSection/VideoEmbeddedWithCTA'))
// Content blocks
const HeadingWithDescription = dynamic(() => import('./ContentSection/HeadingWithDescription'))
// Testimonials blocks
const TestimonialsBlock = dynamic(() => import('./Testimonials/GridLayoutTestimonialCards'))
const Blockquote = dynamic(() => import('./Testimonials/Blockquote'))
const Quote = dynamic(() => import('./Testimonials/Quote'))
// Footer blocks
const DefaultFooter = dynamic(() => import('./FooterSection/DefaultFooter'))
const PreFooterCTA = dynamic(() => import('./FooterSection/PreFooterCTA'))
const FooterWithPatnerInfo = dynamic(() => import('./FooterSection/FooterWithPatnerInfo'))
// Customer logos blocks
const CustomerLogoGridLayout = dynamic(() => import('./CustomerLogosSection/CustomerLogoGridLayout'))
const CustomerLogoCardsAndCTAs = dynamic(() => import('./CustomerLogosSection/CustomerLogoCardsAndCTAs'))
// Social proof blocks
const SocialProofDefault = dynamic(() => import('./SocialProof/SocialProofDefault'))
// Navigations
const Header = dynamic(() => import('./Headers'))
// HTMLBlock
const HTMLBlock = dynamic(() => import('./HTMLBlock'))
// Trust elements
const TrustElementsWithPictures = dynamic(() => import('./TrustElements/TrustElementsWithPictures'))
// Diagnosis entry
const DefaultDiagnosisEntry = dynamic(() => import('./DiagnosisSection/DefaultDiagnosisEntry'))
const DiagnosisSelection = dynamic(() => import('./DiagnosisSection/DiagnosisSelection'))

/**
 * Storyblok Objects
 */
const ProgressBar = dynamic(() => import('@/components/ProgressBar'))
const StarRating = dynamic(() => import('@/components/StarRating'))
const Icon = dynamic(() => import('./Utils/Icon'))

// V2 Components
const SectionActionCardV2 = dynamic(() => import('@/v2/storyblok/sections/SectionActionCardV2'))
const SectionFaqV2 = dynamic(() => import('@/v2/storyblok/sections/SectionFaqV2'))
const SectionContactBoxV2 = dynamic(() => import('@/v2/storyblok/sections/SectionContactBoxV2'))
const SectionIFrameV2 = dynamic(() => import('@/v2/storyblok/sections/SectionIFrameV2'))
const SectionImageCardsV2 = dynamic(() => import('@/v2/storyblok/sections/SectionImageCardsV2'))
const SectionImageTextV2 = dynamic(() => import('@/v2/storyblok/sections/SectionImageTextV2'))
const SectionInfoBannerV2 = dynamic(() => import('@/v2/storyblok/sections/SectionInfoBannerV2'))
const SectionInfoTextSliderV2 = dynamic(() => import('@/v2/storyblok/sections/SectionInfoTextSliderV2'))
const SectionStepsV2 = dynamic(() => import('@/v2/storyblok/sections/SectionStepsV2'))
const SectionTextBoxesV2 = dynamic(() => import('@/v2/storyblok/sections/SectionTextBoxesV2'))
const SectionTextRowsV2 = dynamic(() => import('@/v2/storyblok/sections/SectionTextRowsV2'))
const SectionStageV2 = dynamic(() => import('@/v2/storyblok/sections/SectionStageV2'))
const SectionStageLightV2 = dynamic(() => import('@/v2/storyblok/sections/SectionStageLightV2'))
const SectionStageVideoV2 = dynamic(() => import('@/v2/storyblok/sections/SectionStageVideoV2'))
const SectionTeamV2 = dynamic(() => import('@/v2/storyblok/sections/SectionTeamV2'))
const SectionTextColumnsV2 = dynamic(() => import('@/v2/storyblok/sections/SectionTextColumnsV2'))
const SectionTwoColumnActionCardV2 = dynamic(() => import('@/v2/storyblok/sections/SectionTwoColumnActionCardV2'))
const SectionReviewsV2 = dynamic(() => import('@/v2/storyblok/sections/SectionReviewsV2'))
const SectionReferenceV2 = dynamic(() => import('@/v2/storyblok/sections/SectionReferenceV2'))
const SectionSourcesV2 = dynamic(() => import('@/v2/storyblok/sections/SectionSourcesV2'))
const SectionZShapeV2 = dynamic(() => import('@/v2/storyblok/sections/SectionZShapeV2'))
const SectionLogosV2 = dynamic(() => import('@/v2/storyblok/sections/SectionLogosV2'))

export default {
  blockquote: Blockquote,
  headingWithCTAButton: HeadingWithCTAButton,
  alternateImageWithFeatureList: AlternateImageWithFeatureList,
  featureListWithCTAs: FeatureListWithCTAs,
  header: Header,
  visualImageWithHeading: VisualImageWithHeading,
  socialProofDefault: SocialProofDefault,
  gridLayoutTestimonialCards: TestimonialsBlock,
  headingWithDescription: HeadingWithDescription,
  customerLogoGridLayout: CustomerLogoGridLayout,
  customerLogoCardsAndCTAs: CustomerLogoCardsAndCTAs,
  defaultFooter: DefaultFooter,
  preFooterCTA: PreFooterCTA,
  footerWithPatnerInfo: FooterWithPatnerInfo,
  htmlBlock: HTMLBlock,
  trustElementsWithPictures: TrustElementsWithPictures,
  VideoEmbeddedWithCTA: VideoEmbeddedWithCTA,
  faqAsAccordion: FAQAsAccordion,
  defaultDiagnosisEntry: DefaultDiagnosisEntry,
  diagnosisSelection: DiagnosisSelection,
  progressBar: ProgressBar,
  starRating: StarRating,
  imageWithFeatureListAndCTAs: ImageWithFeatureListAndCTAs,
  icon: Icon,
  quote: Quote,
  sectionStageV2: SectionStageV2,
  sectionStageLightV2: SectionStageLightV2,
  sectionStageVideoV2: SectionStageVideoV2,
  sectionTextBoxesV2: SectionTextBoxesV2,
  sectionTeamV2: SectionTeamV2,
  sectionTextColumnsV2: SectionTextColumnsV2,
  sectionIFrameV2: SectionIFrameV2,
  sectionImageCardsV2: SectionImageCardsV2,
  sectionStepsV2: SectionStepsV2,
  sectionImageTextV2: SectionImageTextV2,
  sectionInfoTextSliderV2: SectionInfoTextSliderV2,
  sectionTextRowsV2: SectionTextRowsV2,
  sectionTwoColumnActionCardV2: SectionTwoColumnActionCardV2,
  sectionActionCardV2: SectionActionCardV2,
  sectionInfoBannerV2: SectionInfoBannerV2,
  sectionFaqV2: SectionFaqV2,
  sectionReviewsV2: SectionReviewsV2,
  sectionReferenceV2: SectionReferenceV2,
  sectionSourcesV2: SectionSourcesV2,
  sectionZShapeV2: SectionZShapeV2,
  sectionContactBoxV2: SectionContactBoxV2,
  sectionLogosV2: SectionLogosV2,
}
