import React from 'react'
import { SbBlokData } from '@storyblok/react'
import UtilButtonV2 from '../utils/UtilButtonV2'
import UtilLinkV2 from '../utils/UtilLinkV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import classNames from 'classnames'
import BaseLinkV2 from '@/v2/base/BaseLinkV2'

type Props = {
  blok: SbBlokData & ISbCoreBlogPostV2
  isFeatured?: boolean
  linkText: string
  link: string
}

const BlogPostCardV2 = ({ blok, isFeatured = false, linkText, link }: Props) => {
  return (
    <BaseLinkV2
      link={{ cached_url: link, url: link }}
      className={classNames(
        'relative flex flex-col overflow-hidden',
        isFeatured ? 'md:grid md:grid-cols-2' : 'rounded-5xl border border-v2-foreground-static-tertiary',
      )}
    >
      {/* Image */}
      {blok.image?.filename && (
        <div className={classNames('h-67', isFeatured ? 'relative overflow-hidden rounded-xl md:h-auto' : 'md:h-72')}>
          <BaseImageV2
            src={blok.image.filename}
            alt={blok.image.alt}
            className="h-full w-full overflow-hidden [&_img]:h-full [&_img]:w-full"
            breakPointsWidthMap={{ 0: 500 }}
            objectCover
          />

          {isFeatured && <div className="absolute inset-0 rounded-xl border border-v2-foreground-static-tertiary" />}
        </div>
      )}

      {/* Content */}
      <div
        className={classNames(
          'flex flex-col gap-6',
          isFeatured ? 'justify-center pt-4 md:pl-6 md:pr-0 lg:pl-12' : ' grow justify-between p-8',
        )}
      >
        <div className="flex flex-col gap-2">
          {blok.category && (
            <p className="body-3-bold mb-2 w-fit rounded-md bg-v2-background-blue px-2 py-1 text-v2-text-primary">
              {blok.category.name}
            </p>
          )}
          {blok.title && <h4 className="heading-4 text-v2-text-primary">{blok.title}</h4>}
          {blok.subtitle && <p className="body-3 text-v2-text-primary">{blok.subtitle}</p>}
        </div>

        {isFeatured ? (
          <div className="w-full md:w-fit">
            <UtilButtonV2
              blok={{
                component: 'utilButtonV2',
                _uid: '',
                icon_right: 'chevron-right',
                text: linkText,
                theme: 'green-dark',
              }}
              isIconRightOnMobileEnabled={true}
            />
          </div>
        ) : (
          <UtilLinkV2
            blok={{
              component: 'utilLinkV2',
              _uid: '',
              title: linkText,
              icon_right: 'chevron-right',
              additional_text: '',
            }}
            isBold
            isIconSmall
          />
        )}
      </div>
    </BaseLinkV2>
  )
}

export default BlogPostCardV2
