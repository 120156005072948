import React, { AriaRole, FC, ReactNode, useMemo } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import isArray from 'lodash/isArray'
import omit from 'lodash/omit'

type Props = {
  link?: ISbLink
  children: ReactNode
  role?: AriaRole
  className?: string
}

const BaseLinkV2: FC<Props> = ({ link, children, role, className }) => {
  const router = useRouter()
  const linkUrl = link?.story?.url || link?.url || link?.cached_url
  const isSectionLink = linkUrl?.startsWith('section=')
  const isExternal = !!link && link.linktype === 'url' && !isSectionLink
  const isEmail = !!link && link.linktype === 'email'
  const isTel = !!linkUrl && linkUrl.startsWith('tel:')
  const isJavascriptAction = linkUrl && linkUrl.startsWith('javascript:')

  const queryParams = useMemo(() => {
    const rest = omit(router.query, 'slug')
    if (!rest) {
      return ''
    }
    const keys = Object.keys(rest)
    const keyValuePairs = keys.map((key) => {
      const item = isArray(rest[key]) ? Array(rest[key]).join(',') : rest[key]
      return item ? encodeURIComponent(key) + '=' + encodeURIComponent(item as string) : ''
    })
    return keyValuePairs.join('&')
  }, [router.query])

  const queryParamString = queryParams.toString()

  const url = useMemo(() => {
    let _url = linkUrl

    if (isSectionLink) {
      return '#'
    }

    if (!_url || isJavascriptAction) {
      return undefined
    }

    if (!!queryParamString && isExternal) {
      const qp = _url.includes('?') ? `&${queryParamString}` : `?${queryParamString}`
      return _url.concat(qp)
    }

    if (isExternal || isTel) {
      return _url
    }

    if (isEmail) {
      return `mailto:${_url}`
    }

    if (!_url.endsWith('/')) {
      _url += '/'
    }

    if (!_url.startsWith('/')) {
      _url = `/${_url}`
    }

    return _url
  }, [linkUrl, isExternal, isEmail, isTel, isJavascriptAction, isSectionLink, queryParamString])

  const onClick = (event: React.BaseSyntheticEvent) => {
    if (isSectionLink && !!linkUrl) {
      event.preventDefault()
      const sectionNumber = parseInt(linkUrl?.split('=')[1])
      const $section = document.querySelector(`.core-page > div:nth-child(${sectionNumber})`)

      if ($section) {
        const isDesktop = window.innerWidth >= 1024
        const headerOffset = isDesktop ? 64 : 100

        // Scroll to section first to prevent wrong position
        if (!isDesktop) {
          $section.scrollIntoView()
        }

        setTimeout(() => {
          const top = $section.getBoundingClientRect().top + (window.pageYOffset - headerOffset)

          window.scrollTo({
            top: sectionNumber === 1 ? 0 : top,
            behavior: isDesktop ? 'smooth' : 'instant',
          })
        }, 1)
      }

      return
    }

    if (isJavascriptAction) {
      event.preventDefault()
      eval(linkUrl as string)
    }
  }

  if (url) {
    return (
      <Link
        role={role}
        className={className}
        href={isExternal ? url : { pathname: url, query: queryParams }}
        rel={isExternal ? 'noopener noreferrer' : ''}
        target={isExternal ? '_blank' : ''}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <div className={className} role={role} onClick={onClick}>
        {children}
      </div>
    )
  }
}

export default BaseLinkV2
