import { ISbRichtext } from '@storyblok/react'
import { GridColRatio } from '@/interfaces/components/layout.interface'

export const isProd = process.env.NODE_ENV === 'production'
export const assetPrefix = isProd ? '/cms' : ''

export const setGridColRatio = (ratio: GridColRatio): string => {
  const classes = {
    [GridColRatio['1/1']]: 'lg:grid-cols-1/1',
    [GridColRatio['1/2']]: 'lg:grid-cols-1/2',
    [GridColRatio['2/1']]: 'lg:grid-cols-2/1',
  }

  return ratio ? classes[ratio] : classes['1/1']
}

export const parseToISbRichtextSchema = (text: ISbRichtext | string): ISbRichtext => {
  if (typeof text === 'string') {
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: text,
              type: 'text',
            },
          ],
        },
      ],
    } as ISbRichtext
  }
  return text as ISbRichtext
}

export const getUsedHtmlTags = (htmlString: string) => {
  const tagRegex = /<\s*([a-zA-Z]+)[^>]*>/g
  const matches = htmlString.match(tagRegex) || []
  const uniqueTags = [...Array.from(new Set(matches.map((match) => match.toLowerCase().match(/<\s*([a-zA-Z]+)/)?.[1])))]

  return uniqueTags
}
