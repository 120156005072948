import React, { useEffect, useMemo } from 'react'
import { ISbStoryData } from '@storyblok/react'
import ItemExpertV2 from '../items/ItemExpertV2'
import classNames from 'classnames'
import ItemIconButtonV2 from '../items/ItemIconButtonV2'
import ItemNumberButtonV2 from '../items/ItemNumberButtonV2'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useBrowser } from '@/hooks/useBrowser'

type Props = {
  story: ISbStoryData<ISbCoreExpertsOverviewV2>
  experts: ISbStoryData<ISbCoreExpertProfileV2>[]
}

const CoreExpertsOverviewV2 = ({ story, experts }: Props) => {
  const { isDesktop } = useBrowser()

  const router = useRouter()

  const blok = story.content

  const maxItemsPerPage = parseInt(blok.max_items_per_page) || 20

  const categories = experts
    .map((expert) => expert.content.expert_category)
    .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates

  const activeDepartmentIndex = router.query.department ? categories.indexOf(router.query.department as string) : 0
  const activePageIndex = router.query.page ? parseInt(router.query.page as string) - 1 : 0

  const startIndex = useMemo(() => activePageIndex * maxItemsPerPage, [activePageIndex, maxItemsPerPage])

  const endIndex = useMemo(() => (activePageIndex + 1) * maxItemsPerPage, [activePageIndex, maxItemsPerPage])

  const expertsFiltered = useMemo(() => {
    return experts.filter((expert) => expert.content.expert_category === categories[activeDepartmentIndex])
  }, [experts, categories, activeDepartmentIndex])

  const expertsShown = useMemo(() => {
    return expertsFiltered.slice(startIndex, endIndex)
  }, [expertsFiltered, startIndex, endIndex])

  const isBeginning = useMemo(() => activePageIndex === 0, [activePageIndex])

  const isEnd = useMemo(
    () => activePageIndex === Math.floor(expertsFiltered.length / maxItemsPerPage) - 1,
    [activePageIndex, expertsFiltered.length, maxItemsPerPage],
  )

  const getVisiblePages = (currentPage: number, totalPages: number) => {
    if (!isDesktop) {
      if (totalPages <= 2) {
        return Array.from({ length: totalPages }, (_, i) => i + 1)
      } else {
        if (currentPage <= 1) {
          return [1, 2, '...']
        } else if (currentPage >= totalPages - 1) {
          return ['...', currentPage, currentPage + 1]
        } else {
          return ['...', currentPage, currentPage + 1, '...']
        }
      }
    } else {
      if (totalPages <= 5) {
        return Array.from({ length: totalPages }, (_, i) => i + 1)
      } else {
        if (currentPage <= 2) {
          return [1, 2, 3, '...', totalPages]
        } else if (currentPage >= totalPages - 1) {
          return [1, '...', totalPages - 2, totalPages - 1, totalPages]
        } else {
          return [1, '...', currentPage, currentPage + 1, '...', totalPages]
        }
      }
    }
  }

  useEffect(() => {
    // Reset page when changing department
    // Scroll to top of the expert list when changing department
    const $list = document.querySelector('.expert-list')
    if (!$list) return

    window.scrollTo({
      top: $list.getBoundingClientRect().top + window.scrollY - 150,
      behavior: 'smooth',
    })
  }, [activeDepartmentIndex])

  return (
    <div className="core-page overflow-x-hidden md:overflow-visible">
      {!!(blok.title || blok.text) && (
        <div className="w-full bg-v2-secondary-static-2 pb-18 pt-10 md:pb-20 md:pt-24">
          <div className="mx-auto flex max-w-7xl flex-col gap-6 px-4 pt-16 lg:px-10">
            {!!blok.title && (
              <h1 className="heading-1 hyphens-auto whitespace-pre-line break-words text-v2-text-primary">{blok.title}</h1>
            )}
            {!!blok.text && <p className="body-1 whitespace-pre-line text-v2-text-primary">{blok.text}</p>}
          </div>
        </div>
      )}

      <div className="container-v2 mx-auto flex flex-col gap-14 py-20 md:gap-10 md:py-32 lg:flex-row">
        {/* Departments */}
        <div className="top-40 h-fit w-96 shrink-0 lg:sticky">
          <div className="body-3 w-fit pb-2 text-v2-foreground-static-secondary">{blok.label_department}</div>
          {categories.map((department, i) => (
            <Link
              key={department}
              href={{ pathname: router.pathname, query: { ...router.query, department } }}
              className="body-2 group relative block w-fit cursor-pointer border-l-2 border-v2-foreground-static-tertiary py-2 text-v2-foreground-static-secondary"
            >
              <span
                className={classNames(
                  'absolute bottom-3 left-0 top-3 -ml-0.5 w-[2px] border-l-2 border-transparent transition-all group-hover:border-v2-text-primary group-hover:font-bold ',
                  {
                    'border-v2-text-primary font-bold ': activeDepartmentIndex === i,
                  },
                )}
              ></span>
              <span
                className={classNames('px-3 group-hover:text-v2-text-primary', {
                  'font-bold text-v2-text-primary': activeDepartmentIndex === i,
                })}
              >
                {department}
              </span>
            </Link>
          ))}
        </div>

        {/* Experts */}
        <div className="expert-list flex w-full flex-col gap-7 md:gap-8">
          <div className="heading-4 flex flex-col px-4 md:flex-row md:justify-between md:pr-16">
            <span className="text-v2-text-primary">{categories[activeDepartmentIndex]}</span>
            {blok.is_experts_count_visible && (
              <span className="text-v2-text-secondary">
                {expertsFiltered.length} {blok.label_experts}
              </span>
            )}
          </div>

          <div className="flex flex-col gap-18 md:gap-20">
            <div className="flex flex-col gap-6">
              {expertsShown.map((expert, index) => (
                <ItemExpertV2
                  key={index}
                  blok={{
                    component: 'childrenExpertsItemV2',
                    image: expert.content.expert_image,
                    title: expert.content.expert_name,
                    text: expert.content.expert_text,
                    link: {
                      url: `/${expert.full_slug}`,
                      cached_url: `/${expert.full_slug}`,
                    },
                  }}
                />
              ))}
            </div>

            {/* Pagination */}
            {expertsFiltered.length > maxItemsPerPage && (
              <div className="flex items-center gap-3">
                <ItemIconButtonV2
                  ariaLabel="Page Previous"
                  icon="chevron-left-v2"
                  href={{ pathname: router.pathname, query: { ...router.query, page: activePageIndex } }}
                  disabled={isBeginning}
                />

                {getVisiblePages(activePageIndex, Math.ceil(expertsFiltered.length / maxItemsPerPage)).map((page, index) =>
                  page === '...' ? (
                    <span key={index} className="text-v2-foreground-static-primary body-1 font-bold tracking-wider">
                      ...
                    </span>
                  ) : (
                    <ItemNumberButtonV2
                      key={index}
                      number={page as number}
                      isActive={(page as number) - 1 === activePageIndex}
                      href={{ pathname: router.pathname, query: { ...router.query, page } }}
                    />
                  ),
                )}

                <ItemIconButtonV2
                  ariaLabel="Page Next"
                  icon="chevron-right-v2"
                  href={{ pathname: router.pathname, query: { ...router.query, page: activePageIndex + 2 } }}
                  disabled={isEnd}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoreExpertsOverviewV2
