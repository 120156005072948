import React, { ElementType, FC } from 'react'
import Icon from '@/components/Icon'
import classNames from 'classnames'
import Link from 'next/link'
import { Url } from 'next/dist/shared/lib/router/router'

type Props = {
  icon: string
  className?: string
  ariaLabel?: string
  disabled?: boolean
  href?: Url
  isLarge?: boolean
  onClick?: () => void
}

const ItemIconButtonV2: FC<Props> = ({ icon, className, ariaLabel, disabled, href, isLarge, onClick }: Props) => {
  const ButtonElement = href && !disabled ? Link : ('button' as ElementType)

  return (
    <ButtonElement
      type="button"
      aria-label={ariaLabel}
      disabled={disabled}
      href={href}
      onClick={onClick}
      className={classNames(
        'box-border flex shrink-0 transform items-center justify-center rounded-full border transition',
        className,
        disabled
          ? 'border-v2-foreground-static-tertiary text-v2-foreground-static-tertiary'
          : 'border-v2-foreground-static-secondary',
        { 'cursor-not-allowed': disabled },
        isLarge ? 'size-24' : 'size-16',
      )}
    >
      <span className="sr-only">{ariaLabel}</span>
      <Icon className={classNames('shrink-0', isLarge ? 'size-8' : 'size-6')} name={icon} />
    </ButtonElement>
  )
}

export default ItemIconButtonV2
