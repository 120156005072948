import Image from 'next/image'
import React, { FC, useMemo } from 'react'

interface PictureProps {
  src: string
  alt?: string
  focus?: string
  breakPointsWidthMap: { [key: string]: number }
  heightRatio?: number[]
  lazy?: boolean
  objectCover?: boolean
  className?: string
  imageClassName?: string
}

const BaseImageV2: FC<PictureProps> = ({
  src,
  alt,
  focus,
  breakPointsWidthMap,
  heightRatio,
  lazy = true,
  objectCover = false,
  className,
  imageClassName,
}) => {
  const breakPoints = useMemo(() => Object.keys(breakPointsWidthMap).reverse(), [breakPointsWidthMap])

  const heighestWidth = useMemo(
    () => Object.values(breakPointsWidthMap)[Object.values(breakPointsWidthMap).length - 1],
    [breakPointsWidthMap],
  )

  const altString = useMemo(() => alt || src?.split('/').slice(-1)[0].split('.')[0] || '', [alt, src])

  const isSVG = useMemo(() => src?.endsWith('.svg'), [src])

  const getSrcResized = (width: number | undefined): string => {
    const height = getHeightByRatio(width)

    if (!src) {
      return ''
    }

    if (src === '' && width) {
      return `https://via.placeholder.com/${width}x${height || width}` // placeholder in case of empty src
    }

    if (!src || isSVG) {
      return src || ''
    }

    let srcString = `${src}/m/`

    if (width) {
      srcString += `${width * 2}x${height * 2}` // add 2x for retina
    }

    if (focus) {
      srcString += `/filters:focal(${focus})`
    }

    return srcString
  }

  const getHeightByRatio = (width: number | undefined): number => {
    return width && heightRatio ? Math.round((width * heightRatio[1]) / heightRatio[0]) : 0
  }

  const defaultSrc = getSrcResized(heighestWidth)

  return (
    <picture className={className}>
      {breakPoints.map((breakPoint) => (
        <source
          key={breakPoint}
          media={`(min-width: ${breakPoint}px)`}
          srcSet={getSrcResized(breakPointsWidthMap[breakPoint])}
        />
      ))}
      <Image
        className={`${objectCover ? 'h-screen w-full object-cover' : ''} ${imageClassName}`}
        src={defaultSrc}
        alt={altString}
        width={heighestWidth}
        height={getHeightByRatio(heighestWidth)}
        loading={lazy ? 'lazy' : 'eager'}
      />
    </picture>
  )
}

export default BaseImageV2
