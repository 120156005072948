import React, { useEffect, useState } from 'react'
import { storyblokEditable, SbBlokData } from '@storyblok/react'
import classNames from 'classnames'
import BaseRichText from '@/v2/base/BaseRichText'

type Props = {
  blok: SbBlokData & IBlogContentTableV2
}

const BlogContentBannerV2 = ({ blok }: Props) => {
  const [maxCells, setMaxCells] = useState(0)

  useEffect(() => {
    const _maxCells = Math.max(...blok.columns.map((column) => column.cells.length))
    setMaxCells(_maxCells)
  }, [blok.columns])

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('overflow-scroll', {
        '-ml-4 w-screen px-4 md:m-0 md:w-auto md:p-0': blok.is_horizontally_scrollable,
      })}
    >
      <div
        className={classNames('w-full overflow-hidden rounded-2xl border border-v2-foreground-static-tertiary', {
          ' min-w-192 md:min-w-auto': blok.is_horizontally_scrollable,
        })}
      >
        <table className="w-full table-fixed border-collapse">
          <thead>
            <tr>
              {blok.columns.map((column, index) => (
                <th
                  {...storyblokEditable(column)}
                  key={index}
                  className="body-3-bold bg-v2-secondary-static-2 p-3 text-left text-v2-text-primary"
                >
                  <div>{column.heading}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: maxCells }, (_, rowIndex) => (
              <tr key={rowIndex}>
                {blok.columns.map((column, columnIndex) => (
                  <td
                    {...storyblokEditable(column.cells[rowIndex])}
                    key={columnIndex}
                    className={classNames(' body-3 border-v2-foreground-static-tertiary p-3 text-v2-text-primary', {
                      'border-b': rowIndex < maxCells - 1,
                    })}
                  >
                    {column.cells?.[rowIndex]?.text ? <BaseRichText value={column.cells[rowIndex].text} /> : ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BlogContentBannerV2
