import React, { useEffect, useMemo, useState } from 'react'
import { SbBlokData } from '@storyblok/react'
import BaseLinkV2 from '@/v2/base/BaseLinkV2'
import Icon from '@/components/Icon'
import classNames from 'classnames'

type Props = {
  blok: SbBlokData & ISbUtilButtonV2
  size?: 'sm' | 'md' | 'lg'
  hasIcon?: boolean
  isIconRightOnMobileEnabled?: boolean
}

const UtilButtonV2 = ({ blok, size, hasIcon = true, isIconRightOnMobileEnabled }: Props) => {
  const [isDesktop, setIsDesktop] = useState(false)

  const textColor = useMemo(() => {
    switch (blok.theme) {
      case 'green-dark':
        return 'white'
      case 'white':
        return 'v2-primary-static-1'
      case 'blue':
        return 'v2-text-primary'
      default:
        return 'primary'
    }
  }, [blok.theme])

  // DON'T REMOVE: For Tailwind safelist classes:
  // text-white
  // text-primary
  // text-v2-text-primary
  // text-v2-primary-static-1
  // [&_path]:!fill-white
  // [&_path]:!fill-primary
  // [&_path]:!fill-v2-text-primary
  // [&_path]:!fill-v2-primary-static-1

  const themeClasses = `bg-v2-background-${blok.theme || 'green-dark'}`

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <BaseLinkV2
      className={classNames(
        // The hardcoded min-[25rem] is needed as extending Tailwind breaks the legacy unit tests for some reason
        'min-[25rem]:px-6 flex items-center justify-center gap-2 rounded-full px-6 py-4 transition',
        `text-${textColor}`,
        themeClasses,
        {
          'body-3-bold': size === 'sm',
          'body-1-bold': size !== 'sm',
          'justify-center hover:bg-v2-background-green-darker': blok.theme === 'green-dark',
          'border border-v2-foreground-static-tertiary hover:bg-v2-background-blue-darker ': blok.theme === 'blue',
        },
      )}
      link={blok.link}
      role={'button'}
    >
      <span className="w-max text-balance text-center">{blok.text}</span>
      {hasIcon && isDesktop && (
        <Icon
          className={`block [&_path]:!fill-${textColor} ${isIconRightOnMobileEnabled ? 'hidden lg:block' : ''}`}
          name={'chevron-right-v2'}
          width={24}
          height={24}
        />
      )}
    </BaseLinkV2>
  )
}

export default UtilButtonV2
