import React, { useState, useEffect } from 'react'
import { SbBlokData } from '@storyblok/react'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import BaseLinkV2 from '@/v2/base/BaseLinkV2'
import UtilLinkV2 from '../utils/UtilLinkV2'
import UtilLanguageSwitchV2 from '../utils/UtilLanguageSwitchV2'
import UtilButtonV2 from '../utils/UtilButtonV2'
import Icon from '@/components/Icon'
import classNames from 'classnames'
import { map } from 'lodash'

type Props = {
  blok: SbBlokData & ISbCoreHeaderV2
  hasHeaderBorder: boolean
}

const CoreHeaderV2 = ({ blok, hasHeaderBorder }: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [activeTopNavigationIndex, setActiveTopNavigationIndex] = useState<number | null>(null)

  const toggleMobileMenu = () => {
    // Setting html for Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    if (isSafari) {
      document.documentElement.style.overflow = isMobileMenuOpen ? 'auto' : 'hidden'
    } else {
      document.body.style.overflow = isMobileMenuOpen ? 'auto' : 'hidden'
    }
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 110)
  }

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      document.body.style.overflow = 'auto'
      setIsMobileMenuOpen(false)
    }
  }

  const handleNavigationClick = (index: number | null, isHovering: boolean | null) => {
    // Mobile sub menu

    if (isHovering === null) {
      setActiveTopNavigationIndex(activeTopNavigationIndex === index ? null : index)
      return
    }

    // Desktop sub menu
    setActiveTopNavigationIndex(isHovering ? index : null)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    !!blok && (
      <header
        className={classNames('sticky z-50 w-full transition lg:top-0', isScrolled || !hasHeaderBorder ? 'top-2' : 'top-0.5', {
          'h-dvh pb-4': isMobileMenuOpen,
        })}
      >
        {blok.header_top_navigation?.length ? (
          <div className="hidden bg-v2-background-surface-3 px-8 py-2 lg:block">
            <div className="container-v2 px-0">
              <div className="flex justify-end">
                <div className="flex items-center gap-8">
                  {map(blok.header_top_navigation[0]?.links, (link, index) =>
                    link.component === 'utilLinkV2' ? (
                      <UtilLinkV2 blok={link} key={index} size="sm" />
                    ) : (
                      <UtilLanguageSwitchV2 key={index} size="sm" />
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Main Header */}
        <div
          className={classNames('w-full lg:absolute lg:px-8', {
            'h-full': isMobileMenuOpen,
          })}
        >
          <div
            className={classNames(
              'lg:container-v2 flex h-full flex-col border bg-v2-secondary-static-1 backdrop-blur-2xl transition lg:mx-auto lg:my-4 lg:max-w-7xl lg:rounded-2xl lg:!p-4',
              activeTopNavigationIndex === null ? 'overflow-hidden' : 'overflow-visible',
              {
                'border-v2-secondary-static-2 p-6 lg:rounded-b-none lg:border-b-v2-foreground-static-tertiary':
                  !isScrolled && hasHeaderBorder,
                'm-2 rounded-2xl border-v2-foreground-static-tertiary p-4 lg:border-v2-secondary-static-2':
                  isScrolled || !hasHeaderBorder,
                'm-2 rounded-2xl border border-v2-foreground-static-tertiary bg-v2-secondary-static-2 !p-4 lg:m-0 lg:border-v2-secondary-static-2 lg:p-0':
                  isMobileMenuOpen,
              },
            )}
          >
            <div className="flex justify-between pl-3">
              {/* Logo */}
              <BaseLinkV2 link={blok.logo_link} className="flex w-34 items-center md:w-50">
                <BaseImageV2
                  src={blok.logo.filename}
                  alt={blok.logo.alt}
                  breakPointsWidthMap={{ 0: 136, 768: 200 }}
                  lazy={false}
                />
              </BaseLinkV2>

              {/* Navigation */}
              <nav className="hidden items-center lg:flex lg:gap-8">
                {blok.links.map((link, index) =>
                  link.component === 'utilLinkV2' ? (
                    <UtilLinkV2 key={index} blok={link} isBold={false} isRounded={true} />
                  ) : (
                    <div
                      key={index}
                      className="relative"
                      onMouseEnter={() => handleNavigationClick(index, true)}
                      onMouseLeave={() => handleNavigationClick(index, false)}
                    >
                      <div
                        className={classNames(
                          'flex cursor-pointer items-center gap-2 rounded-full px-6 py-4 transition hover:bg-v2-background-surface-3',
                          { 'bg-v2-background-surface-3': activeTopNavigationIndex === index },
                        )}
                      >
                        <span className=" text-v2-text-primary">{link.title}</span>
                        <div className="h-5 w-5 shrink-0">
                          <Icon
                            name={activeTopNavigationIndex === index ? 'chevron-top-v2' : 'chevron-down-v2'}
                            width={20}
                            height={20}
                          />
                        </div>
                      </div>

                      {/* Sub Menu */}
                      <div className="absolute w-max pt-2">
                        <div
                          className={classNames(
                            'flex flex-col gap-3 rounded-xl border border-v2-secondary-static-2 bg-v2-background-surface-3 p-4',
                            { hidden: activeTopNavigationIndex !== index },
                          )}
                        >
                          {link.links.map((subLink, subIndex) => (
                            <div key={subIndex} className="px-4 py-3">
                              <UtilLinkV2 blok={subLink as ISbUtilLinkV2} isVerticalAligned={true} size="lg" />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </nav>

              {/* Button */}
              {!!blok.button?.length && (
                <div className="hidden items-center justify-center lg:flex">
                  <UtilButtonV2 blok={blok.button[0]} size="sm" hasIcon={false} />
                </div>
              )}

              {/* Mobile Menu button */}
              <div
                className="flex items-center justify-center rounded-full border border-v2-foreground-static-secondary p-4 lg:hidden"
                role="button"
                aria-label="Toggle mobile menu"
                onClick={toggleMobileMenu}
              >
                <Icon name={isMobileMenuOpen ? 'close' : 'menu'} width={24} height={24} />
              </div>
            </div>

            {isMobileMenuOpen && (
              <div className="flex flex-1 flex-col justify-between p-3">
                {/* Top Section */}
                <div className="flex flex-col gap-6 py-6">
                  {blok.links.map((link, index) =>
                    link.component === 'utilLinkV2' ? (
                      <UtilLinkV2 key={index} blok={link} size="lg" onClick={toggleMobileMenu} />
                    ) : (
                      <div key={index} className="relative">
                        <div
                          className={classNames('flex items-center justify-between gap-2 ')}
                          onClick={() => handleNavigationClick(index, null)}
                        >
                          <span className="body-1-bold text-v2-text-primary">{link.title}</span>
                          <Icon
                            name={activeTopNavigationIndex === index ? 'chevron-top-v2' : 'chevron-down-v2'}
                            width={20}
                            height={20}
                          />
                        </div>

                        <div
                          className={classNames('mt-4 flex w-max flex-col pl-3', {
                            hidden: activeTopNavigationIndex !== index,
                          })}
                        >
                          {link.links.map((subLink, subIndex) => (
                            <div key={subIndex} className="py-4">
                              <UtilLinkV2
                                blok={subLink as ISbUtilLinkV2}
                                isVerticalAligned={true}
                                size="lg"
                                onClick={toggleMobileMenu}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ),
                  )}
                </div>

                {/* Bottom Section */}
                <div className="flex flex-col gap-8 py-6">
                  <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-3">
                      {map(
                        blok.header_top_navigation?.[0]?.links,
                        (link, index) =>
                          link.component === 'utilLinkV2' && (
                            <UtilLinkV2 key={index} blok={link} size="sm" isIconRightOnMobileEnabled={false} />
                          ),
                      )}
                    </div>
                    {!!blok.button?.length && <UtilButtonV2 blok={blok.button[0]} size="sm" hasIcon={false} />}
                  </div>

                  {map(
                    blok.header_top_navigation?.[0]?.links,
                    (link, index) =>
                      link.component === 'utilLanguageSwitchV2' && <UtilLanguageSwitchV2 key={index} size="sm" />,
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    )
  )
}

export default CoreHeaderV2
