import React from 'react'
import classNames from 'classnames'
import Link from 'next/link'

type Props = {
  isActive: boolean
  text: string
  link: string
}

const UtilTagV2 = ({ isActive, text, link }: Props) => {
  return (
    <Link
      href={link}
      className={classNames(
        'cursor-pointer rounded-full border border-v2-foreground-static-tertiary px-6 py-4 transition-colors',
        {
          'bg-v2-background-blue': isActive,
        },
      )}
    >
      <div className={classNames('text-v2-text-primary', isActive ? 'body-3-bold' : 'body-3')}>{text}</div>
    </Link>
  )
}

export default UtilTagV2
