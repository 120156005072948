import React from 'react'
import ItemIconButtonV2 from '../items/ItemIconButtonV2'
import classNames from 'classnames'

type Props = {
  isIconRotated?: boolean
  text: string
  icon: string
  onClick?: () => void
}

const UtilIconTextButtonV2 = ({ isIconRotated, text, icon, onClick }: Props) => {
  return (
    <div onClick={onClick} className="flex cursor-pointer items-center gap-3">
      <ItemIconButtonV2 icon={icon} className={classNames({ 'rotate-45': isIconRotated })} ariaLabel="Toggle Button" />
      <div className="body-1-bold text-v2-text-primary">{text}</div>
    </div>
  )
}

export default UtilIconTextButtonV2
