import dynamic from 'next/dynamic'
// Flobite components
export const Accordion = {
  Default: dynamic(() => import('flowbite-react').then((m) => m.Accordion)),
  Panel: dynamic(() => import('flowbite-react').then((m) => m.Accordion.Panel)),
  Title: dynamic(() => import('flowbite-react').then((m) => m.Accordion.Title)),
  Content: dynamic(() => import('flowbite-react').then((m) => m.Accordion.Content)),
}
export const Dropdown = {
  Default: dynamic(() => import('flowbite-react').then((m) => m.Dropdown)),
}
export const Navbar = {
  Default: dynamic(() => import('flowbite-react').then((m) => m.Navbar)),
  Brand: dynamic(() => import('flowbite-react').then((m) => m.Navbar.Brand)),
  Toggle: dynamic(() => import('flowbite-react').then((m) => m.Navbar.Toggle)),
  Collapse: dynamic(() => import('flowbite-react').then((m) => m.Navbar.Collapse)),
}
// Components
export const Icon = dynamic(() => import('@/components/Icon'))
export const CTAButton = dynamic(() => import('@/components/CTAButton'))
export const RichText = dynamic(() => import('@/components/RichText'))
