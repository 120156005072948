'use client'

import { ISbStoryData } from '@storyblok/react'
import { createContext, useContext } from 'react'

// If new languages are added, update the LANGUAGE_NAME_MAP
const LANGUAGE_NAME_MAP: Record<string, string> = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  de: 'Deutsch',
  nl: 'Nederlands',
  pt: 'Português',
  pl: 'Polski',
  'ch-en': 'English',
  'ch-de': 'Deutsch',
  'ch-fr': 'Français',
  'ch-it': 'Italiano',
}

export const LANGUAGE_HTML_LANG_MAP: Record<string, string> = {
  en: 'en',
  es: 'es',
  fr: 'fr',
  it: 'it',
  de: 'de',
  nl: 'nl',
  pt: 'pt',
  pl: 'pl',
  'ch-en': 'en-CH',
  'ch-de': 'de-CH',
  'ch-fr': 'fr-CH',
  'ch-it': 'it-CH',
}

type ILanguage = {
  name: string
  url: string
}

const LanguagesContext = createContext<{
  languages: ILanguage[]
}>({
  languages: [],
})

const getLanguageName = (fullSlug: string) => {
  const id = fullSlug.split('/')[0]

  return LANGUAGE_NAME_MAP[id] || id
}

const getLanguageUrl = (fullSlug: string) => {
  return fullSlug.startsWith('/') ? fullSlug : `/${fullSlug}`
}

export const LanguagesContextProvider = ({ children, story }: { children: React.ReactNode; story: ISbStoryData }) => {
  const languages: ILanguage[] = [
    {
      name: getLanguageName(story.full_slug),
      url: getLanguageUrl(story.full_slug),
    },
    ...story.alternates
      .map((alternate) => ({
        name: getLanguageName(alternate.full_slug),
        url: getLanguageUrl(alternate.full_slug),
      }))
      .filter((language) => !!language.name),
  ]

  return <LanguagesContext.Provider value={{ languages }}>{children}</LanguagesContext.Provider>
}

export const useLanguagesContext = () => useContext(LanguagesContext)
