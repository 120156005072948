export interface ComponentWrapper {
  id?: string
  disableLayoutPadding?: boolean
  backgroundColor?: string
  backgroundImage?: string
  className?: string
  children: React.ReactNode
}

// Matches tailwind.config.js "gridTemplateColumns" config
export enum GridColRatio {
  '1/1' = '1/1',
  '1/2' = '1/2',
  '2/1' = '2/1',
}

interface Layout {
  children: React.ReactNode
}

export default Layout
