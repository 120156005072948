import React from 'react'
import { StoryblokComponent, ISbStoryData } from '@storyblok/react'

type Props = {
  story: ISbStoryData<ISbCoreComponentV2>
}

const CoreComponentV2 = ({ story }: Props) => {
  const blok = story.content

  return (
    <div className="core-page">
      {blok.body ? (
        blok.body.map((blok, index) => <StoryblokComponent key={index} blok={blok} />)
      ) : (
        <div className="h-screen bg-v2-secondary-static-1"></div>
      )}
    </div>
  )
}

export default CoreComponentV2
