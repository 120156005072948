import React from 'react'
import { SbBlokData } from '@storyblok/react'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import UtilButtonV2 from '../utils/UtilButtonV2'
import UtilLinkV2 from '../utils/UtilLinkV2'
import BaseLinkV2 from '@/v2/base/BaseLinkV2'
import UtilLanguageSwitchV2 from '../utils/UtilLanguageSwitchV2'
import classNames from 'classnames'

type Props = {
  blok: SbBlokData & ISbCoreFooterV2
}

const CoreFooterV2 = ({ blok }: Props) => {
  const isFooterAlternative = !blok?.link_lists?.length
  const isLogoTop = blok?.logo_alignment === 'top'

  return (
    !!blok && (
      <footer className="bg-v2-secondary-static-1 pb-14 pt-14 md:px-8 lg:pb-16 lg:pt-16">
        <div className="container-v2 mx-auto">
          <div className="flex flex-col gap-14 md:gap-16">
            {isLogoTop && (
              <div className="flex max-w-96 items-center pt-6 md:w-96 lg:pt-16">
                <BaseImageV2 src={blok.logo?.filename} alt={blok.logo?.alt} breakPointsWidthMap={{ 0: 384 }} />
              </div>
            )}

            {/* Link lists */}
            {!isFooterAlternative && (
              <ul className="grid grid-cols-2 gap-20 lg:grid-cols-3">
                {blok.link_lists?.map((list, index) => (
                  <li key={index} className="col-span-full md:col-span-1">
                    <div className="heading-5 mb-4 text-v2-text-primary">{list.title}</div>
                    <ul className="flex flex-col gap-3">
                      {list.links.map((item, index) => (
                        <li key={index} className={classNames({ 'pt-3 md:w-fit': item.component === 'utilButtonV2' })}>
                          {item.component === 'utilButtonV2' && <UtilButtonV2 hasIcon={false} blok={item} size="sm" />}
                          {item.component === 'utilLinkV2' && <UtilLinkV2 blok={item} />}
                          {item.component === 'utilLanguageSwitchV2' && <UtilLanguageSwitchV2 />}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}

            {/* Separator */}

            {(isLogoTop || !isFooterAlternative) && <div className="h-px w-full bg-v2-foreground-static-tertiary"></div>}

            <div
              className={classNames(
                'flex flex-col justify-between gap-8 md:flex-row lg:gap-3',
                isLogoTop ? 'md:items-start' : 'md:items-end',
              )}
            >
              <div className="flex flex-col gap-8 lg:flex-row lg:items-baseline lg:gap-10">
                {/* Alternative Logo */}
                {!isLogoTop && (
                  <div className="mr-3 flex w-34 items-center lg:mx-3">
                    <BaseImageV2 src={blok.logo?.filename} alt={blok.logo?.alt} breakPointsWidthMap={{ 0: 384 }} />
                  </div>
                )}

                {/* Bottom links */}
                <ul className="flex flex-col gap-x-6 gap-y-3 md:flex-row">
                  {blok.bottom_links.map((item, index) => (
                    <li className="flex cursor-pointer items-start" key={index}>
                      <BaseLinkV2 className="caption-bold text-v2-text-primary" link={item.link}>
                        {item.title}
                      </BaseLinkV2>
                    </li>
                  ))}
                </ul>

                <div className="caption-default text-v2-text-primary lg:m-0">{blok.bottom_copyright}</div>
              </div>

              {/* Bottom image */}
              <div className={isLogoTop ? 'h-15' : 'h-11 object-contain'}>
                <BaseImageV2
                  src={blok.bottom_image.filename}
                  alt={blok.bottom_image.alt}
                  breakPointsWidthMap={{ 0: 200 }}
                  className="h-full [&_img]:h-full [&_img]:w-auto [&_img]:object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  )
}

export default CoreFooterV2
