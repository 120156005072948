import React, { useMemo } from 'react'
import { SbBlokData } from '@storyblok/react'
import UtilButtonV2 from '../utils/UtilButtonV2'
import classNames from 'classnames'

type Props = {
  blok: SbBlokData & IBlogContentBannerV2
}

const BlogContentBannerV2 = ({ blok }: Props) => {
  const background = useMemo(() => {
    switch (blok.background) {
      case 'beige': {
        return 'bg-v2-background-beige text-v2-text-primary'
      }
      case 'blue': {
        return 'bg-v2-background-blue text-v2-text-primary'
      }
      case 'green-dark': {
        return 'bg-v2-background-green-dark text-white'
      }
      case 'white':
      default: {
        return 'bg-v2-background-white text-v2-text-primary'
      }
    }
  }, [blok.background])

  return (
    <div className={classNames(background, 'rounded-xl border border-v2-foreground-static-tertiary p-7')}>
      <div className="heading-2 mb-6">{blok.title}</div>
      {blok.text && <div className="body-1-bold mb-7">{blok.text}</div>}

      {blok.button.map((button, index) => (
        <div key={index} className="w-full text-center lg:w-fit">
          <UtilButtonV2 blok={button} />
        </div>
      ))}
    </div>
  )
}

export default BlogContentBannerV2
