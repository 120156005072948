import React, { useEffect, useState } from 'react'
import { ISbStoryData, ISbRichtext } from '@storyblok/react'
import BaseLinkV2 from '@/v2/base/BaseLinkV2'
import Icon from '@/components/Icon'
import BlogContentBannerV2 from '../blog/BlogContentBannerV2'
import BlogContentRichtextV2 from '../blog/BlogContentRichtextV2'
import BlogContentCalloutV2 from '../blog/BlogContentCalloutV2'
import BlogContentVideoV2 from '../blog/BlogContentVideoV2'
import BlogContentImageV2 from '../blog/BlogContentImageV2'
import BlogContentTableV2 from '../blog/BlogContentTableV2'
import BaseRichText from '@/v2/base/BaseRichText'
import UtilIconTextButtonV2 from '../utils/UtilIconTextButtonV2'
import Link from 'next/link'

type Props = {
  story: ISbStoryData<ISbCorePageTextV2>
  labels: ISbCoreLabelsV2
}

type ISource = {
  link: string
  title: string
  date: string
  number: string
}

const OFFSET_HEIGHT = 164

const CorePageTextV2 = ({ story, labels }: Props) => {
  const blok = story.content

  const [isSourcesExpanded, setIsSourcesExpanded] = useState(false)
  const hasSubtitle = !!blok.subtitle?.content?.length

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
  }

  const getSourcesFromStory = (): ISource[] => {
    if (!blok?.body) return []

    const links = blok.body
      .filter((blok) => blok.component === 'blogContentRichtextV2')
      .map((blok) => {
        const _blok = blok as IBlogContentRichtextV2
        const _richtext = _blok.richtext as ISbRichtext

        if (!_richtext?.content) return null

        const _links = _richtext.content
          .filter((item) => item.type === 'paragraph')
          .map((item) =>
            item.content?.filter(
              (item) => !!item.marks?.length && item.marks.some((mark) => mark.type === 'link' && !!mark.attrs?.custom?.date),
            ),
          )

        return _links.flat()
      })
      .filter((item) => !!item)
      .flat() as ISbRichtext[]

    const sources: ISource[] = links
      .map((link) => {
        const _link = link as ISbRichtext

        if (!_link?.marks?.length) return { link: '', title: '', date: '', number: '' }

        const _linkText = _link.marks?.[0].attrs?.custom.title || ''
        const _linkHref = _link.marks?.[0].attrs?.href || ''
        const _linkDate = _link.marks?.[0].attrs?.custom.date || ''
        const _linkNumber = _link.marks?.[0].attrs?.custom.number || ''

        return {
          link: _linkHref,
          title: _linkText,
          date: _linkDate,
          number: _linkNumber,
        }
      })
      .filter((item) => !!item.link)

    return sources
  }

  const sources: ISource[] = getSourcesFromStory()

  const onSourceClick = () => {
    const sourceItemClass = 'blog-source'
    const sourceWrapperId = 'blog-sources'

    const $sources = document.getElementsByClassName(sourceItemClass)
    const $sourceWrapper = document.getElementById(sourceWrapperId)

    if (!$sourceWrapper) return

    // when clicked, scroll to the sources
    Array.from($sources).forEach((source) => {
      source.addEventListener('click', () => {
        window.scrollTo({
          top: $sourceWrapper.offsetTop - OFFSET_HEIGHT,
          behavior: 'smooth',
        })
      })
    })
  }

  useEffect(() => {
    onSourceClick()
  }, [])

  return (
    <div className="overflow-x-hidden">
      {/* Top */}
      <div className="bg-v2-secondary-static-2 pb-12 md:pb-16">
        <div className="container-v2 pt-10 lg:pt-35">
          <nav className="mb-16 pl-4 lg:pl-7">
            <ul className="flex items-center">
              {(story.content?.breadcrumbs || []).map((breadcrumb, index) => (
                <>
                  <li className="rounded-xl px-2 py-1 text-v2-text-primary hover:bg-v2-background-surface-3" key={index}>
                    <BaseLinkV2 className="body-2 cursor w-fit" link={breadcrumb.link}>
                      {breadcrumb.title}
                    </BaseLinkV2>
                  </li>
                  <Icon name="chevron-right-v2" className="my-2 opacity-75 last:hidden" width={16} height={16} />
                </>
              ))}
            </ul>
          </nav>

          {/* Latest publish */}
          {story.published_at && !story.content.disable_last_update && (
            <div className="body-3 mb-2 flex flex-wrap items-center gap-2 text-v2-foreground-static-secondary lg:px-10">
              {labels.label_blog_updated_at} <b>{formatDate(story.published_at)} </b>
            </div>
          )}

          <div className="text-v2-text-primary lg:px-10 ">
            <h1 className="heading-1 mb-6 hyphens-auto break-words">{blok.title}</h1>
            {hasSubtitle && (
              <div className="mb-6">
                <BaseRichText
                  value={blok.subtitle}
                  classNames={{ container: 'body-1 prose prose-a:text-v2-primary-static-1' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="container-v2">
        <div className="mb-16 flex shrink-0 flex-col-reverse justify-between gap-x-4 lg:mb-20 lg:flex-row lg:gap-x-12 lg:px-10 xl:gap-x-16">
          {/* Text */}
          <div className="text-content md:text-blog-content flex w-full max-w-3xl flex-col gap-10 pt-12 md:pt-14">
            {blok.body?.map(
              (bodyItem, index) =>
                (bodyItem.component === 'blogContentRichtextV2' && (
                  <div className="prose-styling">
                    <BlogContentRichtextV2 key={index} blok={bodyItem} />
                  </div>
                )) ||
                (bodyItem.component === 'blogContentBannerV2' && <BlogContentBannerV2 key={index} blok={bodyItem} />) ||
                (bodyItem.component === 'blogContentCalloutV2' && <BlogContentCalloutV2 key={index} blok={bodyItem} />) ||
                (bodyItem.component === 'blogContentVideoV2' && <BlogContentVideoV2 key={index} blok={bodyItem} />) ||
                (bodyItem.component === 'blogContentImageV2' && <BlogContentImageV2 key={index} blok={bodyItem} />) ||
                (bodyItem.component === 'blogContentTableV2' && <BlogContentTableV2 key={index} blok={bodyItem} />),
            )}
          </div>
        </div>
      </div>

      {/* Sources */}
      {!!sources?.length && (
        <div id="blog-sources" className="container-v2 mb-20 lg:mb-24">
          <div className="heading-3 mb-3 text-v2-text-primary">{labels.label_blog_sources}</div>
          {/* Mocked for now */}
          <div className="relative">
            <ul className={`${isSourcesExpanded ? '' : 'h-72 overflow-hidden'} pt-4`}>
              {sources.map((source, index) => (
                <li className="mb-6 flex" key={index}>
                  <sup className="sup top-2 text-[65%] text-v2-primary-static-1">{source.number}</sup>
                  <div className="flex flex-col">
                    <p className="body-1 mb-2 text-v2-text-primary">{source.title}</p>
                    <Link href={source.link} className="body-3 mb-2 text-v2-primary-static-1 hover:underline">
                      <span>{source.link}</span>
                    </Link>
                    <span className="body-3 text-v2-text-secondary">{source.date}</span>
                  </div>
                </li>
              ))}
            </ul>
            {!isSourcesExpanded && sources?.length > 2 && (
              <div className="bg-red pointer-events-none absolute top-0 h-full w-full bg-gradient-to-t from-white"></div>
            )}
          </div>
          {!isSourcesExpanded && sources?.length > 2 && (
            <UtilIconTextButtonV2 text={labels.label_blog_read_more} icon="plus" onClick={() => setIsSourcesExpanded(true)} />
          )}
        </div>
      )}
    </div>
  )
}

export default CorePageTextV2
