import React from 'react'
import { SbBlokData } from '@storyblok/react'
import BaseLinkV2 from '@/v2/base/BaseLinkV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import classNames from 'classnames'
import { Icon } from '@/utils/lazyloaded'

type Props = {
  blok: SbBlokData & ISbUtilLinkV2
  isBold?: boolean
  isIconSmall?: boolean
  isRounded?: boolean
  isIconRightOnMobileEnabled?: boolean
  isVerticalAligned?: boolean
  size?: 'sm' | 'md' | 'lg'
  onClick?: (event: React.BaseSyntheticEvent) => void
  color?: 'primary' | 'white' | 'green'
}

const UtilLinkV2 = ({
  blok,
  isBold,
  isIconSmall,
  isRounded,
  isIconRightOnMobileEnabled = true,
  size = 'md',
  isVerticalAligned = false,
  onClick,
  color = 'primary',
}: Props) => {
  const isSmall = size === 'sm'
  const textColor =
    color === 'primary'
      ? 'text-v2-text-primary group-hover:text-v2-primary-static-1'
      : color === 'green'
        ? ' text-v2-primary-static-1'
        : 'text-v2-background-white'

  return (
    <BaseLinkV2
      className={classNames([
        'group cursor-pointer rounded-full transition',
        { 'px-6 py-4 hover:bg-v2-background-surface-3': isRounded },
      ])}
      link={blok.link}
    >
      <div
        onClick={onClick}
        className={classNames(['flex', isSmall ? 'gap-1.5' : 'gap-2', isVerticalAligned ? 'flex-col !gap-1' : 'items-center'])}
      >
        {blok.icon?.filename && (
          <div className={classNames('shrink-0', isSmall ? 'h-4 w-4' : 'h-6 w-6')}>
            <BaseImageV2 src={blok.icon.filename} alt={blok.icon.alt} breakPointsWidthMap={{ 0: 24 }} />
          </div>
        )}
        <div className="flex flex-wrap items-center gap-2">
          {blok.title && (
            <div
              className={classNames(textColor, 'max-w-full shrink-0', {
                'font-bold': isBold,
                'caption-bold group-hover:underline': isSmall,
                'body-1-bold': size === 'lg',
              })}
            >
              {blok.title}
            </div>
          )}
          {blok.additional_text && (
            <p
              className={classNames(
                size === 'lg'
                  ? ' text-v2-text-primary group-hover:text-v2-primary-static-1'
                  : 'text-v2-foreground-static-secondary group-hover:text-v2-primary-static-2',
                {
                  'caption-default': isSmall,
                  'body-3': size === 'lg',
                },
              )}
            >
              {blok.additional_text}
            </p>
          )}
          {blok.icon_right && (
            <div
              className={classNames(isSmall || isIconSmall ? 'h-4 w-4' : 'h-6 w-6', {
                'hidden lg:block': !isIconRightOnMobileEnabled,
              })}
            >
              <Icon
                name={blok.icon_right}
                className={classNames(
                  color === 'primary'
                    ? '[&_path]:!fill-v2-text-primary group-hover:[&_path]:!fill-v2-primary-static-1'
                    : '[&_path]:!fill-v2-background-white',
                  'h-full w-full ',
                )}
              />
            </div>
          )}
        </div>
      </div>
    </BaseLinkV2>
  )
}

export default UtilLinkV2
