import React from 'react'
import { SbBlokData } from '@storyblok/react'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import BaseRichText from '@/v2/base/BaseRichText'

type Props = {
  blok: SbBlokData & IBlogContentImageV2
}

const BlogContentImageV2 = ({ blok }: Props) => {
  const hasRichtext = blok.richtext?.content?.length > 0 && blok.richtext_enabled

  return (
    <div>
      <div className={hasRichtext ? 'xl:flex xl:gap-x-3' : ''}>
        <div className={hasRichtext ? 'xl:w-1/2' : ''}>
          {blok.image?.filename && (
            <div className="mb-1.5 overflow-hidden rounded-xl border border-v2-foreground-static-tertiary">
              <BaseImageV2
                src={blok.image.filename}
                alt={blok.image.alt}
                breakPointsWidthMap={{ 0: 776 }}
                className="h-full w-full [&_img]:h-full [&_img]:w-full"
              />
            </div>
          )}
          {blok.caption && <div className="body-3 text-v2-foreground-static-secondary">{blok.caption}</div>}
        </div>
        {hasRichtext && (
          <div className="prose-styling prose-p:mt-0 xl:w-1/2">
            <BaseRichText value={blok.richtext} />
          </div>
        )}
      </div>
    </div>
  )
}

export default BlogContentImageV2
