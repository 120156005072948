import React, { useEffect, useState } from 'react'
import { ISbStoryData, ISbRichtext } from '@storyblok/react'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import BaseLinkV2 from '@/v2/base/BaseLinkV2'
import UtilButtonV2 from '../utils/UtilButtonV2'
import Icon from '@/components/Icon'
import BlogContentBannerV2 from '../blog/BlogContentBannerV2'
import BlogContentRichtextV2 from '../blog/BlogContentRichtextV2'
import BlogContentCalloutV2 from '../blog/BlogContentCalloutV2'
import BlogContentVideoV2 from '../blog/BlogContentVideoV2'
import BlogContentImageV2 from '../blog/BlogContentImageV2'
import BlogContentTableV2 from '../blog/BlogContentTableV2'
import UtilIconTextButtonV2 from '../utils/UtilIconTextButtonV2'
import Link from 'next/link'
import BlogPostCardV2 from '../blog/BlogPostCardV2'
import classNames from 'classnames'

type Props = {
  story: ISbStoryData<ISbCoreBlogPostV2>
  labels: ISbCoreLabelsV2
}

type ISource = {
  link: string
  title: string
  date: string
  number: string
}

const OFFSET_HEIGHT = 164

const CoreBlogPostV2Author = ({ blok }: { blok: ISbChildrenBlogAuthorV2 }) => {
  return (
    <div className="flex">
      {blok?.author.content?.image?.filename && (
        <div className="mr-4 h-16 w-16 min-w-16 overflow-hidden rounded-full border border-v2-foreground-static-tertiary [&_img]:h-full [&_img]:w-full [&_img]:object-cover">
          <BaseImageV2
            src={blok?.author.content?.image.filename}
            alt={blok?.author.content?.image.alt}
            breakPointsWidthMap={{ 0: 80 }}
          />
        </div>
      )}
      <div className="flex flex-col">
        <div className="caption-default mb-0.5 text-v2-text-secondary">{blok?.title}</div>
        <div className="heading-5 mb-2 flex items-center text-v2-text-primary">
          {blok?.author.content?.name && <span>{blok.author.content.name}</span>}
          {blok?.author.content?.has_badge && (
            <div className="ml-1">
              <Icon name="certified-badge-v2" width={20} height={21} />
            </div>
          )}
        </div>
        {blok?.author.content?.role && <div className="body-2 mb-2 text-v2-text-secondary">{blok.author.content.role}</div>}

        {blok?.link.map((item, index) => (
          <BaseLinkV2 key={index} link={item.link} className="body-2-bold flex items-center text-v2-text-primary">
            {item.title}
            <Icon name="chevron-right-v2" className="h-4 w-4 text-v2-text-primary" />
          </BaseLinkV2>
        ))}
      </div>
    </div>
  )
}

const CoreBlogPostV2Widget = ({ blok }: { blok: ISbChildrenBlogWidgetV2 }) => {
  return (
    <div className="rounded-xl bg-v2-background-green-dark p-6 text-white lg:p-8">
      <div className="heading-4 mb-6">{blok?.title}</div>
      {blok?.text && <div className="body-1-bold mb-7">{blok?.text}</div>}

      {blok?.button.map((item, index) => <UtilButtonV2 key={index} blok={item} />)}
    </div>
  )
}

const CoreBlogPostV2 = ({ story, labels }: Props) => {
  const blok = story.content

  const [activeTocItemIndex, setActiveTocItemIndex] = useState(-1)
  const [isSourcesExpanded, setIsSourcesExpanded] = useState(false)

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
  }

  const getTocItems = (blok: IBlogContentRichtextV2): string[] => {
    const text = blok?.richtext

    return (text.content as ISbRichtext[])
      .filter((item) => item.type === 'heading' && item.attrs?.level === 2)
      .map((item) => item.content?.[0].text || '')
  }

  const getTocItemsFromStory = (): string[] => {
    return story.content?.body
      .filter((blok) => blok?.component === 'blogContentRichtextV2')
      .map((blok) => getTocItems(blok as IBlogContentRichtextV2))
      .filter((titles) => titles.length > 0)
      .flat()
  }

  const tocItems: string[] = getTocItemsFromStory()
  const isTocShown = tocItems.length > 0

  const getLinksFromContent = (item: ISbRichtext) => {
    if (item.type === 'list_item') {
      return item.content
        ?.map((listItem: ISbRichtext): ISbRichtext[] => {
          return getLinksFromContent(listItem) || []
        })
        .flat()
    }

    return item.content?.filter(
      (item) => !!item.marks?.length && item.marks.some((mark) => mark.type === 'link' && !!mark.attrs?.custom?.date),
    )
  }

  const getSourcesFromStory = (): ISource[] => {
    if (!blok?.body) return []

    const links = blok.body
      .filter((blok) => blok?.component === 'blogContentRichtextV2')
      .map((blok) => {
        const _blok = blok as IBlogContentRichtextV2
        const _richtext = _blok.richtext as ISbRichtext

        if (!_richtext?.content) return null

        const _links = _richtext.content
          .filter((item) => ['paragraph', 'bullet_list'].includes(item.type))
          .map((item) => {
            if (item.type === 'bullet_list') {
              const _listLinks = item.content?.map((listItem) => {
                const _listItem = listItem as ISbRichtext

                return getLinksFromContent(_listItem)
              })

              return _listLinks?.flat()
            }

            const _textLinks = getLinksFromContent(item as ISbRichtext)

            return _textLinks
          })
          .flat()

        return _links
      })
      .filter((item) => !!item)
      .flat() as ISbRichtext[]

    const sources: ISource[] = links
      .map((link) => {
        const _link = link as ISbRichtext

        if (!_link?.marks?.length) return { link: '', title: '', date: '', number: '' }

        const _linkText = _link.marks?.[0].attrs?.custom.title || ''
        const _linkHref = _link.marks?.[0].attrs?.href || ''
        const _linkDate = _link.marks?.[0].attrs?.custom.date || ''
        const _linkNumber = _link.marks?.[0].attrs?.custom.number || ''

        return {
          link: _linkHref,
          title: _linkText,
          date: _linkDate,
          number: _linkNumber,
        }
      })
      .filter((item) => !!item.link)
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.link === item.link) && index === self.findIndex((t) => t.title === item.title),
      )

    return sources
  }

  const sources: ISource[] = getSourcesFromStory()

  const onSourceClick = () => {
    const sourceItemClass = 'blog-source'
    const sourceWrapperId = 'blog-sources'

    const $sources = document.getElementsByClassName(sourceItemClass)
    const $sourceWrapper = document.getElementById(sourceWrapperId)

    if (!$sourceWrapper) return

    // when clicked, scroll to the sources
    Array.from($sources).forEach((source) => {
      source.addEventListener('click', () => {
        window.scrollTo({
          top: $sourceWrapper.offsetTop - OFFSET_HEIGHT,
          behavior: 'smooth',
        })
      })
    })
  }

  useEffect(() => {
    onSourceClick()
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const $headlinesPassed = tocItems.filter((_, index) => {
        const $blok = document.querySelectorAll('h2')[index]

        if ($blok) {
          const offsetTop = $blok?.getBoundingClientRect().top + window.scrollY - OFFSET_HEIGHT

          return window.scrollY >= offsetTop - 24 // offset of the margin bottom of the prior element
        }

        return false
      })

      const index = $headlinesPassed.length - 1

      setActiveTocItemIndex(index < 0 ? -1 : index)
    }

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Remove the scroll event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [tocItems])

  const onTocItem = (index: number) => {
    const $blok = document.querySelectorAll('h2')[index]

    if ($blok) {
      window.scrollTo({
        top: $blok?.getBoundingClientRect().top + window.scrollY - OFFSET_HEIGHT,
        behavior: 'smooth',
      })
    }
  }

  const hasImage = blok?.image?.filename && !story.content?.image_hidden

  return (
    <div>
      {/* Top */}
      <div
        className={classNames(
          'mb-14 bg-v2-secondary-static-2 pb-12 lg:mb-20 lg:pb-16',
          hasImage ? 'lg:pb-0 lg:shadow-[inset_0_-48px_0px_0px_white]' : 'lg:pb-14',
        )}
      >
        <div className="container-v2 pt-10 lg:pt-35">
          <nav className="mb-16 pl-4 lg:pl-7">
            <ul className="flex items-center">
              {(story.content?.breadcrumbs || []).map((breadcrumb, index) => (
                <>
                  <li className="rounded-xl px-2 py-1 text-v2-text-primary hover:bg-v2-background-surface-3" key={index}>
                    <BaseLinkV2 className="body-2 cursor w-fit" link={breadcrumb.link}>
                      {breadcrumb.title}
                    </BaseLinkV2>
                  </li>
                  <Icon name="chevron-right-v2" className="my-2 shrink-0 opacity-75 last:hidden" width={16} height={16} />
                </>
              ))}
            </ul>
          </nav>

          <div className="body-3 mb-2 flex flex-wrap items-center gap-2 text-v2-foreground-static-secondary lg:px-10">
            {blok?.reading_time && (
              <div className="flex items-center">
                <Icon name="book-v2" className="mr-2" width={14} height={13} />
                <b>
                  <span>{blok?.reading_time} </span>
                  <span>{labels.label_blog_reading_time}</span>
                </b>
              </div>
            )}
            {story.first_published_at && (
              <>
                {blok?.reading_time && <div className="block h-1.5 w-1.5 rounded-full bg-v2-foreground-static-secondary"></div>}
                <div>
                  {labels.label_blog_created_at} <b>{formatDate(story.first_published_at)} </b>
                </div>
              </>
            )}
            {story.published_at && (
              <>
                <div className="block h-1.5 w-1.5 rounded-full bg-v2-foreground-static-secondary"></div>
                <div>
                  {labels.label_blog_updated_at} <b>{formatDate(story.published_at)} </b>
                </div>
              </>
            )}
          </div>

          <div className="text-v2-text-primary lg:px-10">
            <h1 className="heading-1 mb-6 break-words">{blok?.title}</h1>
            {blok?.subtitle && <p className="body-1 mb-6">{blok?.subtitle}</p>}

            {blok?.image?.filename && !blok?.image_hidden && (
              <div className="overflow-hidden rounded-xl border border-v2-foreground-static-tertiary">
                <BaseImageV2
                  src={blok?.image.filename}
                  alt={blok?.image.alt}
                  breakPointsWidthMap={{ 0: 600, 600: 1400 }}
                  className="aspect-[2/1]"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Authors Top */}
      {['top', 'both', undefined].includes(blok.authors_placement) && (
        <div className="container-v2">
          <div
            className={classNames('mb-14 grid gap-7 lg:mb-20 lg:gap-8 lg:px-10', {
              'lg:grid-cols-2': blok?.authors.length === 2,
              'lg:grid-cols-3': blok?.authors.length === 3,
            })}
          >
            {blok?.authors.map((author, index) => <CoreBlogPostV2Author key={index} blok={author} />)}
          </div>
        </div>
      )}

      {/* Content */}
      <div className="container-v2">
        <div className="mb-16 flex shrink-0 flex-col-reverse justify-between gap-x-4 lg:mb-20 lg:flex-row lg:gap-x-12 lg:px-10 xl:gap-x-16">
          {/* Text */}
          <div className="text-content md:text-blog-content flex w-full max-w-full flex-col gap-10">
            {blok?.body?.map(
              (bodyItem, index) =>
                (bodyItem.component === 'blogContentRichtextV2' && (
                  <div className="prose-styling">
                    <BlogContentRichtextV2 key={index} blok={bodyItem} />
                  </div>
                )) ||
                (bodyItem.component === 'blogContentBannerV2' && <BlogContentBannerV2 key={index} blok={bodyItem} />) ||
                (bodyItem.component === 'blogContentCalloutV2' && <BlogContentCalloutV2 key={index} blok={bodyItem} />) ||
                (bodyItem.component === 'blogContentVideoV2' && <BlogContentVideoV2 key={index} blok={bodyItem} />) ||
                (bodyItem.component === 'blogContentImageV2' && <BlogContentImageV2 key={index} blok={bodyItem} />) ||
                (bodyItem.component === 'blogContentTableV2' && <BlogContentTableV2 key={index} blok={bodyItem} />),
            )}
          </div>

          {/* TOC */}
          {(isTocShown || !!blok?.widget?.length) && (
            <div className="bg-background-neutral-light-100 mb-14 w-auto lg:mb-8 lg:bg-transparent">
              <div className="sticky top-40 grid gap-8">
                {isTocShown && (
                  <div className="rounded-xl bg-v2-background-blue p-7 lg:mb-10 lg:bg-white lg:pt-0">
                    <span className="body-3 mb-2 block text-v2-foreground-static-secondary">{labels.label_blog_toc}</span>
                    <ul className="body-2-bold text-v2-foreground-static-secondary lg:font-normal">
                      {tocItems.map((item, index) => (
                        <li
                          key={index}
                          className={`flex cursor-pointer items-center justify-between py-2 lg:border-l-2 lg:p-3  ${activeTocItemIndex === index ? 'border-v2-foreground-static-secondary text-v2-text-primary' : 'border-v2-foreground-static-tertiary text-v2-text-secondary'}`}
                          onClick={() => onTocItem(index)}
                        >
                          <span>{item}</span>
                          <Icon name="chevron-right-v2" className="h-4 w-4 shrink-0 text-v2-text-primary lg:hidden" />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {blok?.widget.map((widget, index) => <CoreBlogPostV2Widget key={index} blok={widget} />)}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Authors Bottom */}
      {['bottom', 'both', undefined].includes(blok.authors_placement) && (
        <div
          className={classNames('container-v2 mb-16 grid gap-7 lg:mb-20 lg:gap-8 lg:px-10', {
            'lg:grid-cols-2': blok?.authors.length === 2,
            'lg:grid-cols-3': blok?.authors.length === 3,
          })}
        >
          {blok?.authors.map((author, index) => <CoreBlogPostV2Author key={index} blok={author} />)}
        </div>
      )}

      {/* Sources */}
      {!!sources?.length && (
        <div id="blog-sources" className="container-v2 mb-20 lg:mb-24">
          <div className="heading-3 mb-3 text-v2-text-primary">{labels.label_blog_sources}</div>
          {/* Mocked for now */}
          <div className="relative">
            <ul className={`${isSourcesExpanded ? '' : 'h-72 overflow-hidden'} pt-4`}>
              {sources.map((source, index) => (
                <li className="mb-6 flex gap-1" key={index}>
                  <sup className="sup top-2 text-[65%] text-v2-primary-static-1">{source.number}</sup>
                  <div className="flex flex-col">
                    <p className="body-1 mb-2 text-v2-text-primary">{source.title}</p>
                    <Link href={source.link} className="body-3 mb-2 text-v2-primary-static-1 hover:underline">
                      <span>{source.link}</span>
                    </Link>
                    <span className="body-3 text-v2-text-secondary">{source.date}</span>
                  </div>
                </li>
              ))}
            </ul>
            {!isSourcesExpanded && sources?.length > 2 && (
              <div className="bg-red pointer-events-none absolute top-0 h-full w-full bg-gradient-to-t from-white"></div>
            )}
          </div>
          {!isSourcesExpanded && sources?.length > 2 && (
            <UtilIconTextButtonV2 text={labels.label_blog_read_more} icon="plus" onClick={() => setIsSourcesExpanded(true)} />
          )}
        </div>
      )}

      {/* Related posts */}
      {!!blok?.related_posts?.length && (
        <div className="container-v2 mb-20 lg:mb-32">
          <h2 className="heading-3 mb-3 text-v2-text-primary">{labels.label_blog_related_posts}</h2>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-8">
            {blok.related_posts.map((relatedPost, index) => (
              <BlogPostCardV2
                key={index}
                blok={relatedPost.content}
                linkText={labels.label_blog_read_more}
                link={relatedPost.full_slug}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CoreBlogPostV2
