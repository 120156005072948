import { useMemo } from 'react'
import type { ISbRichtext } from '@storyblok/react'
import { MARK_LINK, render } from 'storyblok-rich-text-react-renderer'
import first from 'lodash/first'
import { parseToISbRichtextSchema } from '@/utils'
import Link from 'next/link'

interface Props {
  value: ISbRichtext | string | undefined
  classNames?: {
    container?: string
  }
}

const BaseRichText = (props: Props) => {
  const formattedContent = useMemo(() => {
    if (!props.value) {
      return null
    }
    const value = parseToISbRichtextSchema(props.value)

    if (!value.content || value?.content?.length === 0 || !first(value?.content)?.content) return null

    return render(props.value, {
      markResolvers: {
        [MARK_LINK]: (children, props) => {
          const isSourceLink = !!props.custom?.date && !!props.custom?.title

          if (isSourceLink) {
            return <sup className="blog-source cursor-pointer font-bold text-v2-primary-static-1">{children}</sup>
          }

          return (
            <Link
              href={props.href as string}
              className="underline"
              target={props.target}
              rel={props.custom?.rel}
              title={props.custom?.title}
            >
              {children}
            </Link>
          )
        },
      },
    })
  }, [props.value])

  if (!formattedContent) {
    return null
  }

  return <div className={props.classNames?.container}>{formattedContent}</div>
}

export default BaseRichText
