import { ISbStoryData } from '@storyblok/react'
import React, { useMemo } from 'react'
import { first, omit } from 'lodash'
import CorePageV2 from '../storyblok/core/CorePageV2'
import CoreHeaderV2 from '../storyblok/core/CoreHeaderV2'
import CoreFooterV2 from '../storyblok/core/CoreFooterV2'
import { LanguagesContextProvider } from '@/context/languages'
import CoreBlogPostV2 from '../storyblok/core/CoreBlogPostV2'
import CoreComponentV2 from '../storyblok/core/CoreComponentV2'
import CorePageTextV2 from '../storyblok/core/CorePageTextV2'
import CoreExpertProfileV2 from '../storyblok/core/CoreExpertProfileV2'
import CoreExpertsOverviewV2 from '../storyblok/core/CoreExpertsOverviewV2'
import CoreBlogOverviewV2 from '../storyblok/core/CoreBlogOverviewV2'

type Props = {
  settings: ISbStoryData<ISbCoreSettingsV2>
  story: ISbStoryData<ISbCoreContentType>
  childData?: Record<string, ISbStoryData[]>
}

const BaseLayoutV2 = ({ settings, story, childData }: Props) => {
  const hasHeaderBorder = story.content?.component !== 'coreExpertProfileV2'

  const header = useMemo(() => {
    if (story.content.component === 'coreComponentV2') {
      return null
    }

    return story.content.header ? story.content.header : first(settings.content.header)
  }, [story.content, settings.content])

  const footer = useMemo(() => {
    if (story.content.component === 'coreComponentV2') {
      return null
    }

    return story.content.footer ? story.content.footer : first(settings.content.footer)
  }, [story.content, settings.content])

  const labels: ISbCoreLabelsV2 = omit(settings.content, ['header', 'footer', 'component'])

  const renderComponent = () => {
    switch (story.content.component) {
      case 'corePageV2':
        return <CorePageV2 story={story as ISbStoryData<ISbCorePageV2>} />
      case 'coreComponentV2':
        return <CoreComponentV2 story={story as ISbStoryData<ISbCoreComponentV2>} />
      case 'coreBlogPostV2':
        return <CoreBlogPostV2 story={story as ISbStoryData<ISbCoreBlogPostV2>} labels={labels} />
      case 'corePageTextV2':
        return <CorePageTextV2 story={story as ISbStoryData<ISbCorePageTextV2>} labels={labels} />
      case 'coreExpertProfileV2':
        return (
          <CoreExpertProfileV2
            story={story as ISbStoryData<ISbCoreExpertProfileV2>}
            linkBack={settings.content.expert_overview_back}
          />
        )
      case 'coreExpertsOverviewV2': {
        const experts = (childData?.experts as ISbStoryData<ISbCoreExpertProfileV2>[]) ?? []

        return <CoreExpertsOverviewV2 story={story as ISbStoryData<ISbCoreExpertsOverviewV2>} experts={experts} />
      }
      case 'coreBlogOverviewV2': {
        const categories = (childData?.categories as ISbStoryData<ISbCoreBlogCategoryV2>[]) ?? []
        const posts = (childData?.posts as ISbStoryData<ISbCoreBlogPostV2>[]) ?? []

        return <CoreBlogOverviewV2 story={story as ISbStoryData<ISbCoreBlogOverviewV2>} categories={categories} posts={posts} />
      }
      default:
        return null
    }
  }

  return (
    <LanguagesContextProvider story={story}>
      <div className="page-v2">
        {header && <CoreHeaderV2 blok={header?.content} hasHeaderBorder={hasHeaderBorder} />}
        {renderComponent()}
        {footer && <CoreFooterV2 blok={footer?.content} />}
      </div>
    </LanguagesContextProvider>
  )
}

export default BaseLayoutV2
