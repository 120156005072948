import React, { ElementType, FC } from 'react'
import classNames from 'classnames'
import { Url } from 'next/dist/shared/lib/router/router'
import Link from 'next/link'

type Props = {
  number: number
  className?: string
  isActive?: boolean
  onClick?: () => void
  href?: Url
}

const ItemNumberButtonV2: FC<Props> = ({ number, className, isActive, onClick, href }: Props) => {
  const ButtonElement = href ? Link : ('button' as ElementType)

  return (
    <ButtonElement
      type="button"
      aria-label={`Number ${number}`}
      onClick={onClick}
      href={href}
      className={classNames(
        'body-1 box-border flex min-h-16 min-w-16 shrink-0 transform items-center justify-center rounded-full border px-6 py-4 font-bold text-v2-text-primary transition',
        className,
        isActive
          ? 'border-v2-foreground-static-tertiary bg-v2-background-blue'
          : 'border-v2-foreground-static-secondary hover:md:bg-v2-background-blue',
      )}
    >
      {number}
    </ButtonElement>
  )
}

export default ItemNumberButtonV2
