import React from 'react'
import { SbBlokData } from '@storyblok/react'
import BaseRichText from '@/v2/base/BaseRichText'

type Props = {
  blok: SbBlokData & IBlogContentRichtextV2
}

const BlogContentRichtextV2 = ({ blok }: Props) => {
  return (
    <BaseRichText
      value={blok.richtext}
      classNames={{
        container:
          'prose-h1:font-normal prose-h2:font-normal prose-h3:font-normal prose-h4:font-normal prose-h5:font-normal prose-h6:font-normal',
      }}
    />
  )
}

export default BlogContentRichtextV2
