import React from 'react'
import { ISbStoryData, StoryblokComponent } from '@storyblok/react'
import Icon from '@/components/Icon'
import Link from 'next/link'
import UtilLinkV2 from '../utils/UtilLinkV2'
import ItemExpertV2 from '../items/ItemExpertV2'

type Props = {
  story: ISbStoryData<ISbCoreExpertProfileV2>
  linkBack?: [ISbUtilLinkV2]
}

const CorePageV2 = ({ story, linkBack }: Props) => {
  const blok = story.content

  const expert: ISbChildrenExpertsItemV2 = {
    component: 'childrenExpertsItemV2',
    image: blok.expert_image,
    kicker: blok.expert_role,
    title: blok.expert_name,
    subtitle: blok.expert_subtitle,
    text: blok.expert_text,
  }

  const breadcrumbs: ISbUtilLinkV2[] = [
    linkBack ? linkBack[0] : undefined,
    linkBack
      ? {
          ...linkBack[0],
          title: blok.expert_category,
        }
      : undefined,
  ].filter(Boolean) as ISbUtilLinkV2[]

  return (
    <div className="core-page lg:pt-12">
      <div className="container-v2 flex flex-col gap-20 pb-20 pt-[50px] md:gap-32 md:py-32">
        <div className="flex flex-col gap-14 md:gap-16">
          <div className="flex flex-wrap items-center gap-y-1 text-v2-text-secondary">
            {breadcrumbs.map((item, index) => (
              <div key={index} className="flex shrink-0 items-center gap-2 px-2 py-1">
                <Link href={item.link?.cached_url || ''} className="body-2 font-bold text-v2-text-primary">
                  {item.title}
                </Link>
                {breadcrumbs.length !== index + 1 && (
                  <Icon name="chevron-right" className="size-6 text-v2-foreground-static-secondary" />
                )}
              </div>
            ))}
          </div>

          {/* Expert  */}
          {expert && <ItemExpertV2 blok={expert} />}
        </div>

        {/* Expert Profile */}
        <div className="grid gap-8 md:grid-cols-2">
          <div className="flex flex-col gap-2 px-4">
            <span className="heading-6 font-bold text-v2-text-primary">{blok.contact_kicker}</span>
            <p className="heading-4 text-v2-text-primary">{blok.contact_title}</p>
            <p className="body-1 whitespace-pre-line font-bold text-v2-foreground-static-secondary">{blok.contact_text}</p>
          </div>

          <div className="flex flex-col gap-6 rounded-5xl border border-v2-foreground-static-tertiary p-8 md:p-10">
            {blok.contact_links?.map((link, index) => <UtilLinkV2 blok={link} key={index} size="lg" color="green" />)}
          </div>
        </div>
      </div>

      {/* Body */}
      {blok.body ? (
        blok.body.map((blok, index) => <StoryblokComponent key={index} blok={blok} hasTopPadding={false} />)
      ) : (
        <div className="h-screen bg-v2-secondary-static-1"></div>
      )}
    </div>
  )
}

export default CorePageV2
