import React, { useMemo, SVGProps } from 'react'
import dynamic from 'next/dynamic'
// Interface
import { IconBase as IconProps } from '@/interfaces/components/icon.interface'
import { omit } from 'lodash'

const Icon = (props: IconProps) => {
  const IconC = useMemo(() => {
    const iconType = props.type ? props.type : 'icons'
    return dynamic(() => import(`@/../public/${iconType}/${props.name}.svg`).then((mod) => mod.default))
  }, [props.name, props.type])

  return <IconC {...(omit(props, 'type', 'name') as SVGProps<SVGAElement>)} />
}

export default Icon
