import React, { useMemo } from 'react'
import { ISbStoryData } from '@storyblok/react'
import { useRouter } from 'next/router'
import UtilTagV2 from '@/v2/storyblok/utils/UtilTagV2'
import ItemIconButtonV2 from '../items/ItemIconButtonV2'
import ItemNumberButtonV2 from '../items/ItemNumberButtonV2'
import ItemSectionHeadV2 from '../items/ItemSectionHeadV2'
import BlogPostCardV2 from '../blog/BlogPostCardV2'
import { useBrowser } from '@/hooks/useBrowser'

type Props = {
  story: ISbStoryData<ISbCoreBlogOverviewV2>
  categories: ISbStoryData<ISbCoreBlogCategoryV2>[]
  posts: ISbStoryData<ISbCoreBlogPostV2>[]
}

const CoreBlogOverviewV2 = ({ story, categories, posts }: Props) => {
  const { isDesktop } = useBrowser()

  const router = useRouter()

  const postsPerPage = parseInt(story.content.posts_per_page, 10) || 6

  const activeCategorySlug = router.query.category as string | null
  const activePageIndex = router.query.page ? parseInt(router.query.page as string) - 1 : 0

  const activeCategoryStory = useMemo(() => {
    return categories.find((category) => category.slug === activeCategorySlug)
  }, [categories, activeCategorySlug])

  // Pagination post related
  const startIndex = useMemo(() => activePageIndex * postsPerPage, [activePageIndex, postsPerPage])
  const endIndex = useMemo(() => (activePageIndex + 1) * postsPerPage, [activePageIndex, postsPerPage])

  const postsFiltered = useMemo(() => {
    return posts.filter((post) => {
      if (!activeCategorySlug) {
        return true
      }

      const categoryId = post.content.category?.slug

      return categoryId === activeCategorySlug
    })
  }, [posts, activeCategorySlug])

  const postsShown = useMemo(() => {
    return postsFiltered.slice(startIndex, endIndex)
  }, [postsFiltered, startIndex, endIndex])

  const featuredPostShown = useMemo(() => {
    return !activeCategorySlug && !activePageIndex
  }, [activeCategorySlug, activePageIndex])

  const isBeginning = useMemo(() => activePageIndex === 0, [activePageIndex])

  const isEnd = useMemo(
    () => activePageIndex === Math.floor(postsFiltered.length / postsPerPage) - 1,
    [activePageIndex, postsFiltered.length, postsPerPage],
  )

  const getVisiblePages = (currentPage: number, totalPages: number) => {
    if (!isDesktop) {
      if (totalPages <= 2) {
        return Array.from({ length: totalPages }, (_, i) => i + 1)
      } else {
        if (currentPage <= 1) {
          return [1, 2, '...']
        } else if (currentPage >= totalPages - 1) {
          return ['...', currentPage, currentPage + 1]
        } else {
          return ['...', currentPage, currentPage + 1, '...']
        }
      }
    } else {
      if (totalPages <= 5) {
        return Array.from({ length: totalPages }, (_, i) => i + 1)
      } else {
        if (currentPage <= 2) {
          return [1, 2, 3, '...', totalPages]
        } else if (currentPage >= totalPages - 1) {
          return [1, '...', totalPages - 2, totalPages - 1, totalPages]
        } else {
          return [1, '...', currentPage, currentPage + 1, '...', totalPages]
        }
      }
    }
  }

  const generateCategoryLink = (categorySlug: string) => {
    const { pathname, query } = router
    const newQuery = { ...query, category: categorySlug, page: '' }
    const queryString = new URLSearchParams(newQuery).toString().replace(/%2C/g, '/')

    return `${pathname}?${queryString}`
  }

  return (
    <div className="overflow-x-hidden">
      {/* Head */}
      {!!story.content.head?.length && (
        <div className="w-full bg-v2-secondary-static-2 pb-18 pt-26 lg:pb-20 lg:pt-48">
          <div className="mx-auto max-w-7xl px-4 lg:px-10">
            <ItemSectionHeadV2 blok={story.content.head[0]} isPageHead />
          </div>
        </div>
      )}

      <div className="container-v2 mx-auto flex flex-col gap-14 py-20 md:gap-10 lg:py-32">
        {/* Categories */}
        {!!categories?.length && (
          <div className="flex flex-wrap gap-3">
            <UtilTagV2 isActive={!activeCategorySlug} text={story.content.categories_all} link={generateCategoryLink('')} />

            {categories.map((category) => (
              <UtilTagV2
                key={category.id}
                isActive={category.slug === activeCategorySlug}
                text={category.name}
                link={generateCategoryLink(category.slug)}
              />
            ))}
          </div>
        )}

        {/* Category details */}
        {!!activeCategoryStory && (
          <div className="flex max-w-screen-lg flex-col gap-2 md:py-6">
            {activeCategoryStory.content.title && (
              <h2 className="heading-2 text-v2-text-primary">{activeCategoryStory.content.title}</h2>
            )}
            {activeCategoryStory.content.text && (
              <p className="body-1 text-v2-text-primary">{activeCategoryStory.content.text}</p>
            )}
          </div>
        )}

        {/* Featured post */}
        {!!story.content.featured_post && featuredPostShown && (
          <BlogPostCardV2
            blok={story.content.featured_post.content}
            isFeatured={true}
            linkText={story.content.featured_button_text}
            link={story.content.featured_post.full_slug}
          />
        )}

        {/* Posts */}
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
          {postsShown.map((post) => (
            <BlogPostCardV2 key={post.id} blok={post.content} linkText={story.content.post_button_text} link={post.full_slug} />
          ))}
        </div>

        {/* Pagination */}
        {postsFiltered.length > postsPerPage && (
          <div className="flex items-center gap-3">
            <ItemIconButtonV2
              ariaLabel="Page Previous"
              icon="chevron-left-v2"
              href={{ pathname: router.pathname, query: { ...router.query, page: activePageIndex } }}
              disabled={isBeginning}
            />

            {getVisiblePages(activePageIndex, Math.ceil(postsFiltered.length / postsPerPage)).map((page, index) =>
              page === '...' ? (
                <span key={index} className="text-v2-foreground-static-primary body-1 font-bold tracking-wider">
                  ...
                </span>
              ) : (
                <ItemNumberButtonV2
                  key={index}
                  number={page as number}
                  isActive={(page as number) - 1 === activePageIndex}
                  href={{ pathname: router.pathname, query: { ...router.query, page } }}
                />
              ),
            )}

            <ItemIconButtonV2
              ariaLabel="Page Next"
              icon="chevron-right-v2"
              href={{ pathname: router.pathname, query: { ...router.query, page: activePageIndex + 2 } }}
              disabled={isEnd}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CoreBlogOverviewV2
