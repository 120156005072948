import { storyblokEditable } from '@storyblok/react'
import classNames from 'classnames'
import React, { FC } from 'react'

type Props = {
  blok: {
    kicker: string
    title: string
    subtitle: string
    text: string
  }
  isDark?: boolean
  isTextSmall?: boolean
  isPageHead?: boolean
  background?: BackgroundColor
}

const getClasses = (background: BackgroundColor, isDark: boolean) => {
  const isDarkGreen = background === 'green-dark'
  const isBlue = background === 'blue'

  const darkerColor = classNames(isDark ? 'text-white' : 'text-v2-text-primary', {
    'lg:text-white': isDarkGreen,
  })

  const lighterColor = classNames(isDark ? 'text-white' : isBlue ? 'text-v2-text-primary' : 'text-v2-text-secondary', {
    'lg:text-white': isDarkGreen,
  })

  return { darkerColor, lighterColor }
}

const ItemSectionHeadV2: FC<Props> = ({
  blok,
  isDark = false,
  isTextSmall,
  background = 'white',
  isPageHead = false,
}: Props) => {
  const hasHeadContent = blok.kicker || blok.title || blok.text

  const TitleTag = isPageHead ? 'h1' : 'h2'
  const titleSize = isPageHead ? 'heading-1' : isTextSmall ? 'heading-3' : 'heading-2'
  const { darkerColor, lighterColor } = getClasses(background, isDark)

  return (
    hasHeadContent && (
      <div className="flex flex-col gap-2 transition" {...storyblokEditable(blok)}>
        {blok.kicker && (
          <span
            className={classNames('heading-6', darkerColor, isPageHead ? 'text-v2-text-secondary' : 'text-v2-text-primary')}
          >
            {blok.kicker}
          </span>
        )}
        {blok.title && (
          <TitleTag className={classNames('whitespace-pre-line break-words', titleSize, darkerColor)}>
            {blok.title}
            {blok.subtitle && <br />}
            {blok.subtitle && <span className={classNames(titleSize, lighterColor)}>{blok.subtitle}</span>}
          </TitleTag>
        )}
        {blok.text && (
          <p
            className={classNames('body-1 whitespace-pre-line', lighterColor, {
              'mt-4 !text-v2-text-primary': isPageHead,
            })}
          >
            {blok.text}
          </p>
        )}
      </div>
    )
  )
}

export default ItemSectionHeadV2
